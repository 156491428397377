 
<div class="footer">
  
<footer class="footer_section ">
  <div class="container">
    <div class="row py-4">
      <div class="col-md-4 mb-4 col-lg-4">
           <a href="/">
    <div class="logo-class d-flex" >
      <!-- <img src="../../assets/images/link-cart-logo.svg" class="header-logo mr-3 mt-1" /> -->
      <!-- <img src="../../assets/images/fav-icon.png" class="footer-logo mr-3" /> -->
       
        <p>Summarizly AI</p>
         
    </div>
     </a>
    <div class="linkscart-footer">
      <!-- <h4>Platform for people to acquire and share knowledge on any topic</h4>  -->
 <div class="paragraph">
                  <h1>Knowledge about</h1>

      <div class="rotating-words-container" *ngIf="isBrowser">
        <ng-container *ngFor="let word of categoryList; let i = index">
          <span class="word" *ngIf="currentIndex === i">{{ word.name }}</span>
        </ng-container>
      </div>
                 <h1>  in 200 words</h1>

    </div>
       <P>Powered by AI</P>
    </div>
   
      </div>
      <div class="col-md-3 mb-4 col col-lg-3">
        
        <div class="">
        
        </div>
       
      </div>
      <div class="col-md-3 mb-4 col col-lg-3">
       
 
      </div>
      <div class="col-md-2 col-lg-2">
  
      </div>
    </div>

    <div class="row flex-column-reverse flex-md-row border_row">
      <div class="col-sm-12 col-lg-6 col-lg-9 col-xxl-8 box">
        <ul class="list-inline quick-links">
          <li class="list-inline-item text-center mb-2">
            <span class="copyright quick-links pr-md-3">
              @Summarizly AI {{ Date }}
            </span>
          </li>

          <li class="list-inline-item pr-md-4 pr-lg-4 mt-sm-3 mt-md-3">
            <a href="/terms-of-service" routerLink="/terms-of-service">Terms 
              Of Service </a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="/privacy-policy" routerLink="/privacy-policy">Privacy Policy</a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="/cookies-policy" routerLink="/cookies-policy">Cookie Policy</a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="https://form.jotform.com/230251781254451" target="_blank">Contact us</a>
          </li>
        </ul>
      </div>
   
    </div>
  </div>
</footer>

</div>

