<app-creatornavbar></app-creatornavbar>
<div class="main">
  <div class="container">
    <div class="linkProductSection mt-5">
      <!-- <div class="cards-section-collection" *ngIf="cardSectionCollectionView"><h2>What do you want to publish?</h2><div class="cards-section d-flex justify-content-center"><div class="card mr-md-3"><div class="card-body"><div class="collection-view-section"><h4>A collection</h4><img src="../../assets/images/youtube-img.svg"><p>Publish a YouTube video link, and few product links</p></div></div></div><div class="card ml-md-5" (click)="productRoute()"><div class="card-body"><div class="collection-view-section"><h4>A single product link</h4><img src="../../assets/images/p.svg"><p>Publish a single product link</p></div></div></div></div></div> -->
      <div class="link_product" *ngIf="createCollections">
        <!-- <h4>How to link a product?</h4> -->
                <h3>Publish a Blog</h3>

        <div class="publish-link mt-3">
          <app-loader *ngIf="loaderPreview"></app-loader>

          <form [formGroup]="createPowerCollectionEmail">
            <div class="d-md-flex">
              <div class="input">
                <p class="pb-md-1 mt-md-4 ">Enter YouTube video Link</p>
                <input class="form-control" formControlName="sourceURL" placeholder="Paste the youtube video link">
                <div *ngIf="f.sourceURL.errors?.required && submittedSourceLink" class="invalid-feedback m-t d-block"> Source link is required </div>
                <!-- <h6 class="mt-1">Where is the product can be purchased by others in the community</h6> -->
              </div>
            </div>
            <!-- <div class="details-section mt-3">
              <p>Select the Video count</p>
              <select formControlName="videoViewsCount" class="form-control">
                <option value="" selected disabled hidden>Select</option>
                <option *ngFor="let count of VideoCount" [value]="count">{{count}}</option>
              </select>
              <div *ngIf="f.videoViewsCount.errors?.required && submittedSourceLink" class="invalid-feedback m-t d-block">Please select </div>
            </div> -->


            <div class="checkbox-section mt-2">

              
                <div class="d-flex">
                    <div class="checkbox-label ">
                       <p>Target Audience:</p>
                    </div>
                    <div class="form-check ml-3" *ngFor="let gender of gender">
    <input class="form-check-input"  (change)="onChange(gender, $event)" name="{{gender }}" type="checkbox" >
    <label class="form-check-label" for="{{gender}}">
        {{gender}}
    </label>
</div>
</div>
 <div class="d-flex">
                    <div class="checkbox-label mr-3">
                       <p>Target Country:</p>
                    </div>
                    <div class="form-check ml-3 mr-2" *ngFor="let CountryFileds of CountryFileds">
    <input class="form-check-input"  (change)="onChangeCountry(CountryFileds, $event)" name="{{CountryFileds }}" type="checkbox" >
    <label class="form-check-label" for="{{CountryFileds}}">
        {{CountryFileds}}
    </label>
</div>
</div>
 <div class="d-flex">
                    <div class="checkbox-label">
                       <p>Target Language:</p>
                    </div>
                    <div class="form-check ml-3 mr-2" *ngFor="let Language of Language">
    <input class="form-check-input"  (change)="onChangeLanguage(Language, $event)" name="{{Language }}" type="checkbox" >
    <label class="form-check-label" for="{{Language}}">
        {{Language}}
    </label>
</div>
</div>
                
            </div>
          </form>
          <div class="publish-btn mb-3 d-flex">
            <a>
              <p class="mt-5">Back</p>
            </a>
            <button class="pointer" (click)="createCollectionBtn()">Proceed</button>
          </div>
        </div>
      </div>
      <div class="add-product-details" *ngIf="addCollections">
        <div class="back-btn pointer" (click)="goBackaddCollections()">
          <!-- <img src="../../assets/images/back.png" class="back-btn"> -->
          <p>Go Back</p>
        </div>
       <div class="d-flex justify-content-between">
          <div class="step">
            <div class=''>
              <div class="bullet"></div>
              <div class="stepper-class">
                <p>Add YouTube Link</p>
              </div>
            </div>
          </div>
          <div class="step">
            <div class=''>
              <div class="bullet" [class.productLinks]="this.addCollections == true"></div>
              <div class="stepper-class">
                <p>Generate AI Blog</p>
              </div>
            </div>
          </div>
          <div class="step">
            <div class=''>
              <div class="bullet" [class.publishedCompletd]="this.addCollections == true"></div>
            </div>
            <div class="stepper-class ">
              <p>Publish Blog</p>
            </div>
          </div>
        </div>
        <form [formGroup]="createPowerCollectionDetails">
          <div class="row">
           <div class="col-md-4">
              <div class="youtube-thumbanils">
                <img [src]="CImage" width=100 height=100 alt="" class="youtube-thumbnai-img">
              </div>
              <div class=" ">
              <div class=" ">
                 
                <img *ngIf="url != null" [src]="url" width=100 height=100 alt="" class="img-upload-bg">
                 
              
              </div>
            </div>
<p *ngIf="errorView == true" class="error_filesize mt-md-4">{{errorMesseage}}</p>

            <div class="upload-img d-flex mt-md-3">
              <input style="display: none" type="file" accept="image/*"   (change)="onFileSelectedEvent($event)" #fileInput>
              <a class="cursor" (click)="fileInput.click()">Upload Thumbnail</a>
              <!-- <button class="plus-btn ml-2" >+</button> -->
            </div>
               
            </div>
            <div class="col-md-8">
              <div class="addProcductDetails-input">
                <div class="details-section">
                  <p>BLog title</p>
                  <div class="title">
                    <div>
                      <input type="text" formControlName="collectionName" placeholder="Enter the BLog title">
                      <div *ngIf="c.collectionName.errors?.required && submittedDetails" class="invalid-feedback m-t d-block"> Blog name is required </div>
                    </div>
                  </div>
                </div>
                <div class="details-section mt-3">
                 <app-loader *ngIf="summaryLoader"></app-loader>
                <div class="details-section mt-3">
                  <div class="d-flex">
                    <button class="btn btn-block" (click)="getSummary(this.createPowerCollectionEmail.value.sourceURL.slice(
          this.createPowerCollectionEmail.value.sourceURL.indexOf('=') + 1
        ))">Generate AI Blog content</button>
                    <button class="btn btn-block" (click)="getShortSummary(this.createPowerCollectionEmail.value.sourceURL.slice(
          this.createPowerCollectionEmail.value.sourceURL.indexOf('=') + 1
        ))">Generate AI short Blog</button>
                  </div>
                  <p>Summary</p>
                  <div *ngIf="Error != ''" class="invalid-feedback m-t d-block"> {{Error}} </div>
                  <quill-editor *ngIf="!!modules" formControlName="Summery"   [styles]="{height: '800px'}" [modules]="modules"></quill-editor>
                </div>
                  <!-- <div class="summary-title d-flex">
                    <div class="add-s">
                      <h6 class="mt-1" *ngIf="this.collectionServices.summary == ''">Add summery</h6>
                      <div class="d-flex check" *ngIf="this.collectionServices.summary != ''">
                        <h5 class="mt-md-2">Summery added successfully</h5>
                        <img src="../../assets/images/tick-11.png" class="check-img">
                      </div>
                    </div>
                    <div class="add-btn" [class.summaryAdded]="this.collectionServices.summary != ''">
                      <button class="pointer" (click)="summary()" type="button">Add</button>
                    </div>
                  </div> -->
                </div>
                <div class="loader" *ngIf="loadinSubTopics">
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
    <div class="bar4"></div>
    <div class="bar5"></div>
    <div class="bar6"></div>
    <div class="bar7"></div>
    <div class="bar8"></div>
    <div class="bar9"></div>
    <div class="bar10"></div>
    <div class="bar11"></div>
    <div class="bar12"></div>
</div>
                <div class="collection-select d-flex">
                  <div class="details-section mt-3">
                    <p>Publisher Name</p>
                    <div class="title">
                      <div>
                        <select formControlName="channelName" (change)="channelEvent($event)" class="form-control">
                          <option value="" selected disabled hidden>Select Publisher name</option>
                          <option *ngFor="let list of ChannelName" [value]="list.name">{{list.name}}</option>
                        </select>
                        <div *ngIf="c.channelName.errors?.required && submittedDetails" class="invalid-feedback m-t d-block"> Publisher name is required </div>
                      </div>
                    </div>
                  </div>
                  <div class="details-section mt-2 ml-md-1">
                    <div class="catergories-add-section mt-md-2">
                      <p>Topics Name</p>
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="categoryId" multiple placeholder="Select Topics" disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance>
                          <!-- <mat-option class='services-view'  [value]=" ">Select Channel name</mat-option> -->
                          <mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                       <div class="add_topics mt-2">
                      <a class="cursor p-2" (click)="addTopics()">Add topics</a>
                  </div>
                      <div *ngIf="errorMessage != ''" class="invalid-feedback m-t d-block"> {{errorMessage}} </div>
                      <div *ngIf="c.categoryId.errors?.required && submittedDetails" class="invalid-feedback m-t d-block"> Please select the Topics </div>
                    </div>
                  </div>
                
                  <div class="details-section mt-2 ml-md-1">
                    <div class="catergories-add-section mt-md-2">
                      <p>Subtopics Name</p>
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="subCategoryId"  multiple placeholder="Select subtopics" disableOptionCentering panelClass="eligablePanelClass"  #closeattendance>
                          <!-- <mat-option class='services-view'  [value]=" ">Select Channel name</mat-option> -->
                          <mat-option class='services-view' *ngFor="let list of subCaterogoryList" [value]="list">{{list}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="add_topics mt-2">
                      <a class="cursor p-2" (click)="addsubtopics()">Add subtopics</a>
                  </div>
                      <div *ngIf="c.subCategoryId.errors?.required && submittedDetails" class="invalid-feedback m-t d-block"> Please select the Topics </div>
                    </div>
                  </div>
                 
                </div>
                 <div class="details-section mt-3 d-flex">
                  <div class="title">
                    <app-loader [keywordsLoader]="keywordsLoaderPostionChange" *ngIf="keywordsLoader"></app-loader>
                    <p>Keywords</p>
                    <input type="text" (keypress)="keyWords($event)" formControlName="Keywords" placeholder="Enter keywords" class="brand-input">
                  </div>
                  <div class="get-keys">
                    <button class="btn" (click)="getkeyWords()">Generate keywords</button>
                  </div>
                </div>
                <!-- <div class="details-section mt-3"><div class="title"><p>Tags</p><input type="text" (keypress)="tags($event)" formControlName="tags" placeholder="Enter tags" class="brand-input"></div></div> -->
                <!-- <div class="details-section mt-3"><div class="catergories-add-section mt-md-2"><p>Category Name</p><mat-form-field appearance="fill"><mat-select formControlName = "categoryId" multiple placeholder="Select Category"   disableOptionCentering panelClass="eligablePanelClass" (selectionChange)="categoryEvent($event)" #closeattendance ><mat-option class='services-view' *ngFor="let list of categoryList" [value]="list._id">{{list.name}}</mat-option></mat-select></mat-form-field><div *ngIf="c.categoryId.errors?.required && submittedDetails" class="invalid-feedback m-t d-block"> Please select the category </div></div></div> -->
                <!-- <div class="details-section mt-md-3"><p>Do you have any discount code for this product?</p><mat-radio-group aria-label="Select an option"><mat-radio-button value="1" class="mr-5">Yes</mat-radio-button><mat-radio-button value="2" class="ml-4">NO</mat-radio-button></mat-radio-group></div> -->
                <!-- <div class="details-section mt-md-3" *ngIf=""><p>Do you have any discount code for this product?</p><div class="title d-flex"><input type="text" placeholder="Enter Discount code or offer code"><h6 class="ml-1 mt-2 option-class">Optional</h6></div></div> -->
                <div class="checked mt-3">
                  <p>
                    <mat-checkbox [checked]="true" class="disable_ripple">Also post this on Pinterest and make it available</mat-checkbox>
                  </p>
                  <p>
                    <mat-checkbox class="disable_ripple" [checked]="true">I agree to the terms of service and privacy policy of Summarizly AI</mat-checkbox>
                  </p>
                </div>
                <p *ngIf="collectionError != null" class="invalid-feedback m-t d-block">{{collectionError}}</p>
                <div class="publishLinkbtn add-p d-flex mb-md-4">
                  <!-- <button class="mt-3 pointer" data-toggle="modal" data-target="#exampleModalCenter">Publish Blog</button>
                  <button class="mt-4 ml-md-4 pointer product" (click)="addProduct()">Add Product Links <img src="../../assets/images/pr.svg" class="arrow-img-p">
                  </button> -->
                   <button class="mt-4 ml-md-3 pointer Blog_p" data-toggle="modal" data-target="#exampleModalCenter">Publish Blog<img src="../../assets/images/pr.svg" class="arrow-img-p">
                  </button> 
                </div>
                <div class="modal fade" data-keyboard="false" data-backdrop="static" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <img src="../../assets/images/close-m.svg">
                        </button>
                      </div>
                      <div class="modal-body"> Are you sure— you want to publish this blog</div>
                      <div class="modal-footer">
                        <button type="button" class="btn back" data-dismiss="modal">No, take me back</button>
                        <button type="button" class="btn confirm" data-dismiss="modal" (click)="saveCollection()">Yes, publish it</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
   
    <!-- <div class="add-product-details" *ngIf="collectionsAdd">
      <div class="back-btn pointer" (click)="goBackProduct()">
        <p>Go Back</p>
      </div>
      <div class="d-flex justify-content-between">
        <div class="step">
          <div class=''>
            <div class="bullet"></div>
            <div class="stepper-class">
              <p>Add YouTube video</p>
            </div>
          </div>
        </div>
        <div class="step">
          <div class=''>
            <div class="bullet"></div>
            <div class="stepper-class">
              <p>Add product links</p>
            </div>
          </div>
        </div>
        <div class="step">
          <div class=''>
            <div class="bullet" [class.publishedCompletd]="this.collectionsAdd == true"></div>
          </div>
          <div class="stepper-class ">
            <p>Publish collection</p>
          </div>
        </div>
      </div>
      <form [formGroup]="addproductForm">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <img *ngIf="productImageUrl == null" src="../../assets/images/img-bg.svg" class="img-bg">  
                 <a *ngIf="productImageUrl != null" (click)="deleteImage()"><img src="../../assets/images/close-pdf.png" class="close-img-f"></a>
                <img *ngIf="productImageUrl != null" [src]="productImageUrl" width=100 height=100 alt="" class="img-upload-bg">
               <img *ngIf="producturl == null" src="../../assets/images/img-bg.svg" class="img-bg">  
                <img *ngIf="producturl != null" [src]="producturl" width=100 height=100 alt="" class="img-upload-bg">
                <p *ngIf="producturl == null">Add one or more images of the product</p>
              </div>
            </div>
            <div class="upload-img d-flex"><input style="display: none" type="file"  accept="image/*" (change)="onFileSelectedProduct($event)" #fileInput><p>Upload product image</p><button class="plus-btn ml-2" (click)="fileInput.click()">+</button></div>
            <div class="Products-added-section scroll mt-md-5 pt-md-3" *ngIf="collectiontitle != ''">
              <div class="product">
                <p>Products added to the collection</p>
                <div class="" *ngFor="let value of collectiontitle;let i = index;">
                  <div class="d-flex">
                    <div class="mr-md-3 mt-md-2">
                      {{i + 1}}
                    </div>
                    <div class="p-title d-flex mb-md-3">
                      <p class="mr-auto">{{value.title | slice:0:23}}</p>
                      <a (click)="deleteProduct(value.id,i)">
                        <img src="../../assets/images/close-m.svg" class="close-img-view pointer">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
          <app-loader *ngIf="loaderShowProduct"></app-loader>
          <div class="col-md-8">
            <div class="addProcductDetails-input">
              <div class="details-section">
          <app-loader *ngIf="loaderPreviewProduct"></app-loader>

                <p>Enter the destination url</p>
                <div class="d-flex">
                  <div class="title">
                    <input type="text" formControlName="AffiliateLink" placeholder="Enter the Destination url">
                    <div *ngIf="p.AffiliateLink.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Destination url is required </div>
                    <div *ngIf="linkMypreview != null" class="invalid-feedback pr m-t d-block">{{linkMypreview}}</div>
                  </div>
                  <div class="fetch">
                    <button class="pointer ml-md-3 mt-2" (click)="fetchProductDetail()">Fetch</button>
                  </div>
                </div>
              </div>
              <div class="details-section mt-3">
                <p>Enter the product title</p>
                <div class="title">
                  <input type="text" formControlName="productTitle" placeholder="Enter the Title">
                  <div *ngIf="p.productTitle.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Title is required </div>
                </div>
              </div>
              <div class="details-section d-flex mt-3">
                <div class="title">
                  <p>Enter the description</p>
                  <input type="text" formControlName="description" placeholder="Enter the description" class="brand-input">
                  <div *ngIf="p.description.errors?.required && submittedProduct" class="invalid-feedback m-t d-block"> Description is required </div>
                </div>
                <h6 class="ml-5">How to link a product?</h6>
              </div>
           
              <div class="details-section p-select mt-3 d-flex">
           
              </div>
             
              <div class="details-section mt-md-3">
                <div class="d-flex">
                  <p class="mr-md-3">Discount code available?</p>
                  <mat-radio-group aria-label="Select an option" class="mt-1" [selectedIndex]="1">
                    <mat-radio-button [checked]="true" value="0" (change)="changeRoute($event)">
                      <span>No</span>
                    </mat-radio-button>
                    <mat-radio-button class="ml-md-5" value="1" (change)="changeRoute($event)">
                      <span>Yes</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="title d-flex" *ngIf="showDiscount">
                  <input type="text" formControlName="couponCode" placeholder="Enter Discount code or offer code">
                </div>
              </div>
              <div class="checked mt-3">
                <p>
                  <mat-checkbox [checked]="true" class="disable_ripple">Also post this on Pinterest and make it available</mat-checkbox>
                </p>
                <p>
                  <mat-checkbox [checked]="true" class="disable_ripple">I agree to the terms of service and privacy policy of Summarizly AI</mat-checkbox>
                </p>
              </div>
              <p *ngIf="productErroMsg != null" class="invalid-feedback m-t d-block">{{productErroMsg}}</p>
               <div class="publishLinkbtn"><button class="mt-3 pointer" ></button></div>  
              <div class="publishLinkbtn save-p d-flex mb-md-4">
                <button class="mt-3 pointer product" (click)="addProductDetails()">Save & add more products</button>
                <button class="mt-3 ml-md-4 pointer publish" data-toggle="modal" data-target="#publishCollection">Publish collection</button>
              </div>
              <div class="modal fade" data-keyboard="false" data-backdrop="static" id="publishCollection" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="../../assets/images/close-m.svg">
                      </button>
                    </div>
                    <div class="modal-body"> Are you sure you want to publish this collection </div>
                    <div class="modal-footer">
                      <button type="button" class="btn back" data-dismiss="modal">No, take me back</button>
                      <button type="button" class="btn confirm" data-dismiss="modal" (click)="saveCollection()">Yes, publish it</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div> -->
    <div class="login-success-user-section" *ngIf="loginUSerSuccess">
      <!-- <div class="login-user-img ">
        <img src="../../assets/images/check.png" class="success-img">
        <div class="login-details-msg d-flex justify-content-center mt-md-4">
          <h5>Collection published successfully</h5>
          <P class="ml-5 pointer">
            <a routerLink="/c/{{this.createPowerCollectionDetails.value.collectionName
             .split(' ').join('-')}}/id-{{this.id}}">View</a>
          </P>
        </div>
        <div class="loginDetails">
          <p>Would you like to post this on Pinterest as well?</p>
          <div class="d-flex more-community justify-content-center">
            <div class="pin-it mb-2 mt-md-2">
              <button type="button" class="pointer">
                <a data-pin-do="buttonPin" data-pin-count="beside" data-pin-custom="true" data-pin-save="false" data-pin-description="Pinterest specific description here" target="_blank" href="https://www.pinterest.com/pin/create/button/?url={{categoryLink}}/&media={{CImage}}&description=So%20delicious!" data-pin-custom="true">
                  <img src="../../../../assets/images/pinterest (2).png" class="pointer pin-img" alt="pinterest">
                  <span class="save ml-2">save</span>
                </a>
              </button>
            </div>
            <div>
              <h5 class="mt-md-2 ml-md-3">Help more people on your Pinterest community to discover this product</h5>
            </div>
          </div>
          <h4 class="mt-md-4">Share this product link with your friends and help them discover</h4>
          <div class="shareItBtnSection d-flex justify-content-center">
            <input type="text" class="pointer" placeholder="{{categoryLink}}" readonly>
            <button type="button" class="pointer" (click)="share()">Share it</button>
          </div>
        </div>
      </div> -->
       <div class="row">
           <div class="col-md-6 border-p">
             <div class="login-user-img mt-5">
               <img src="../../assets/images/check.png" class="success-img">
               <div class="login-details-msg d-flex justify-content-center mt-md-4">
                 <h5>Blog published successfully</h5>
                 <P class=" ml-2 pointer">
                   <a routerLink="/c/{{this.CollectionTitle
             .split(' ')?.join('-')}}/id-{{this.id}}">View</a>
                 </P>
               </div>
               <h4 class="mt-md-4">Share this Summary with your network and help others discover more summaries</h4>
               <div class="shareItBtnSection d-flex justify-content-center mb-3">
                 <button type="button" class="link pointer">{{this.categoryLink | slice:0:50}}</button>
                 <button type="button" class="pointer" (click)="share()">Share it</button>
               </div>
               <div class="publish-product mt-md-5 mb-md-5">
                 <h6>Want to publish more?</h6>
                 <div class="d-flex mt-4">
                   <div class="publish-collection pointer">
                    <a (click)="publishCollectionLogin()">
                     <div class="d-flex">
                       <img src="../../assets/images/youtube-img.svg">
                       <p class="mt-2">Publish blog</p>
                     </div>
                     </a>
                   </div>
                   <div class="publish-collection ml-md-4 pointer">
                    <a (click)="publishProdcutLogin()">                   
                     <div class="d-flex">
                       <img src="../../assets/images/p.svg">
                       <p class="mt-2">Publish product link</p>
                     </div>
                      </a>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div class="col-md-6">
             <div class="loginDetails">
               <img src="../../assets/images/arr-2.png" class="arrow">
               <div class="more-community justify-content-center">
                 <div class="pin-it mb-2 mt-md-2">
                   <button type="button" class="pointer">
                    <a data-pin-do="buttonPin" data-pin-count="beside" data-pin-custom="true" data-pin-save="false" data-pin-description="Pinterest specific description here" target="_blank" href="https://www.pinterest.com/pin/create/button/?url={{categoryLink}}/&media={{CImage}}&description=So%20delicious!" data-pin-custom="true">
                  <img src="../../../../assets/images/pinterest (2).png" class="pointer pin-img" alt="pinterest">
                  <span class="save ml-2">Pin it</span></a>
                   </button>
                 </div>
                 <div>
                   <h5 class="mt-md-5 ml-md-3">Cross publish this collection to your Pinterest Community and drive more engagement and traffic</h5>
                 </div>
               </div>
             </div>
           </div>
         </div>
    </div>
  </div>
</div>
<app-footer></app-footer>