<nav class="navbar navbar-expand-lg navbar-light fixed-top nav">
  <div class="container">
    <a href="/">
    <div class="logo-class d-flex mr-md-2" >
      <!-- <img src="../../assets/images/link-cart-logo.svg" class="header-logo mr-1 mt-1" /> -->
       
        <p>Summarizly AI</p>
      
    </div>
    </a>
    <div class="logo-class d-flex ml-3 pointer">
     
        <img src="../../assets/images/feed.png" class="header-logo mt-3 mr-1" />
       
        <h2><a (click)="feed()" >Feed</a></h2>
      
    </div>
   
    
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav ml-auto">
         <div class="collections">
            <button type="button" class=" mr-md-2 mt-1 pointer" [disabled]="categoriesBtn" (click)="channelBtn()">
               Create channel</button>
         </div>
         <div class="collections">
            <button type="button" class=" mr-md-2 mt-1 pointer" [disabled]="categoriesBtn" (click)="catergioresBtn()">
               Add topics</button>
         </div>
         <div class="collections pointer" >
            <button type="button" class=" mr-md-2 mt-1 pointer">
               Admin Access</button>
         </div>
         <div class="link-btn mt-md-1">
   <button class="linkProduct" (click)="linkProductBtn()" [disabled]="categoriesBtn" >
        <!-- <img src="../../assets/images/link.svg" class="link-img mr-2" /> -->

           Publish a link
        </button>
         </div>


        
         <!-- <div class="" *ngIf="hideNotifications">
        <img src="../../assets/images/notifications.svg" class="link-img mx-4 mt-3" />
            
         </div> -->
         <div class="" *ngIf="hideadmin">
        <img src="../../assets/images/admin.png" class="admin-img ml-4 mr-2 mt-1" />
         <button mat-icon-button class="Administrator-btn" [disabled]="categoriesBtn"
           
            mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <img src="../../assets/images/a-1.png" class="arrow-img  mt-1 pointer" />

         
							<!-- <mat-icon> <img src="../../assets/images/panel_images/action-dot.svg" id="users-total" class="action-dots" /></mat-icon> -->
							<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" place></div>
						</button>
						<mat-menu #menu="matMenu" xPosition="before">
							<!-- <button class="View" mat-menu-item value="Profile"  (click)="viewlinks()"  ><span>View my links</span>
              </button> -->
							<button class="View" mat-menu-item value="Profile"  (click)="editChannel()"  ><span>Edit channel</span>
              </button>
							<button class="View" mat-menu-item value="Profile"  (click)="editCategories()"  ><span>Edit Topics</span>
              </button>
							<button class="View" mat-menu-item value="Profile" (click)="myCollections()"  ><span>My Summaries</span>
              </button>
							<!-- <button class="View" mat-menu-item value="Profile" (click)="myProducts()"><span>My products</span>
              </button> -->
							<button class="Deactivate" mat-menu-item value="Logout" (click)="logout()" > <img src="../../assets/images/log-out.png" class="mr-3 logout" /> <span>Logout</span>
              </button>
						</mat-menu>
            
         </div>
     
        
      </div>
      
    </div>
  </div>
</nav>