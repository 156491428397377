import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import { CollectionService } from 'src/app/_services/collection.service';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
declare const phyllo: any;

@Component({
  selector: 'app-publisher-content',
  templateUrl: './publisher-content.component.html',
  styleUrls: ['./publisher-content.component.css'],
})
export class PublisherContentComponent implements OnInit {
  account_id: any;
  thumbnailData: any;
  exeternal_id: any;
  loader: boolean;
  showVideos: boolean = false;
  phyllouserId: any;
  phylloSdkToken: any;
  phylloAccountId: any;
  data: string;
  loaderVideos: boolean = false;
  youtubeData: any;
  // channelId: any;
  cId: any;
  channelForm: FormGroup;
  submitted: boolean;
  ChannelName: any;
  id: any;
  params: any;
  publisherBlogs: any;
  urls: any = [];
  urlsYoutube: any = [];
  urls1: any;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  pageSize: any = 10;
  currentPage: any = 0;

  pageSizeOptions: number[] = [10, 20, 40, 100];
  count1: any = 50;
  totalItems: any;
  constructor(
    private formBuilder: FormBuilder,
    private service: CollectionService,
    private router: Router,
    private Publisherservice: AdminService
  ) {
    this.channelForm = this.formBuilder.group({
      channelId: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(UC)?[a-zA-Z0-9_-]{21}[AQgw]/),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.service.creatorChannel().subscribe((res) => {
      this.ChannelName = res.data;
      console.log(this.ChannelName);

      this.id = res?.data[0]?.channel_id;
      this.channelForm.controls['channelId'].setValue(this.id);
      if (this.ChannelName != '') {
        this.FetchVideos();
      }
    });
    this.account_id = localStorage.getItem('accountId');
    console.log(this.account_id);
    this.phyllouserId = localStorage.getItem('userId');
    this.phylloSdkToken = localStorage.getItem('phylloSdkToken');

    this.service.loginUserDetails().subscribe((result) => {
      this.phylloAccountId = result.data?.phylloAccountId;

      if (this.phylloAccountId != '') {
        this.account_id = result.data?.phylloAccountId;
        this.phyllouserId = result.data?.phylloUserId;
        this.phylloSdkToken = result.data?.phylloToken;
        // console.log('accound_id', this.account_id);
        // console.log('accound_id', this.phyllouserId);
        // console.log('accound_id', this.phylloSdkToken);
      }
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.count1 += 10;
    if (this.ChannelName != '') {
      this.FetchVideos();
    }
  }

  channel(e: any) {
    // this.channelId = e.target.value;
    console.log('channel', e.target.value);
  }
  publishBlog(item: any) {
    const queryParams = {
      url: `https://www.youtube.com/watch?v=${item}`,
    };
    this.router.navigate(['publisher/post_type-collection/new'], {
      queryParams,
    });
  }
  get channelId(): { [key: string]: AbstractControl } {
    return this.channelForm.controls;
  }
  FetchVideos() {
    // UCIBgYfDjtWlbJhg--Z4sOgQ
    // this.cId = this.channelId;

    if (this.id == '') {
      this.service
        .getPublisherDetails(this.channelForm.value.channelId)
        .subscribe(
          (results) => {
            console.log(results);
          },
          (err) => {
            console.log(err);
          }
        );
    }

    if (this.channelForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      this.service
        .getYoutubeData(this.channelForm.value.channelId, this.count1)
        .subscribe((data) => {
          console.log(data);
          this.youtubeData = data.items;
          this.totalItems = data.pageInfo.totalResults;
          this.youtubeData.forEach((item: any) => {
            this.urlsYoutube.push(item.id.videoId);
          });
          console.log(this.urlsYoutube);
          this.Publisherservice.PublisherBlogsList(this.params).subscribe(
            (res) => {
              console.log(res);
              this.publisherBlogs = res.totalSummary;

              this.publisherBlogs.forEach((item: any) => {
                this.urls.push(item.thumbNail);
              });
              const videoIds = this.urls.map((url: any) => {
                const startIndex = url.indexOf('/vi/') + 4; // Adding 4 to skip '/vi/'
                const endIndex = url.indexOf('/', startIndex); // Look for the next '/' after '/vi/'
                return url.substring(startIndex, endIndex);
              });
              this.urls1 = videoIds;
              console.log(videoIds);
              // const exists = this.urlsYoutube.some((value: any) =>
              //   videoIds.includes(value)
              // );
              // if (exists) {
              //   this.youtubeData.forEach((youtube: any) => {
              //     youtube.published = true;
              //   });
              //   console.log('At least one element of array1 exists in array2.');
              // } else {
              //   this.youtubeData.forEach((youtube: any) => {
              //     youtube.published = false;
              //   });
              //   console.log('No element of array1 exists in array2.');
              // }
              console.log(this.youtubeData);
            }
          );
        });
    }

    // this.loaderVideos = true;
    // let phylloToken = {
    //   account_id: this.account_id,
    // };
    // this.service.phylloAPIRefreshToken(phylloToken).subscribe((res) => {
    //   console.log(res);
    // });
    // let obj = {
    //   account_id: this.account_id,
    //   from_date: '2023-10-11',
    // };
    // this.service.phylloAPIACcountDetails(obj).subscribe((res) => {
    //   console.log(res);
    //   this.thumbnailData = res.data.data;
    //   this.showVideos = true;
    //   this.loaderVideos = false;
    // });
  }
  GetPhyllo() {
    this.exeternal_id = uuid();
    let phylloObj = {
      userName: 'surya7',
      external_id: this.exeternal_id,
      // environment:`${environment.USER}`
    };

    this.service.phylloAPIUserId(phylloObj).subscribe((res) => {
      console.log('userID', res.data.id);
      let phylloToken = {
        user_id: res.data.id,
      };

      this.service.phylloAPISDkToken(phylloToken).subscribe((token) => {
        console.log('token.data.sdk_token', token.data.sdk_token);
        let obj = {
          userId: res.data.id,
          // token:
          //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNmYzM2IwZTEtMDgyOC00YjBiLTkyYzAtMzVlZDM5NWI1MzgwIiwidGVuYW50X2lkIjoiZGNmODVjMjEtMWUxZi00YzA2LWEzNGYtNzVkMmIxMDEyYzU5IiwidGVuYW50X2FwcF9pZCI6Ijk5OTI3ZjU1LWM1MDktNDkxOC1iOWEyLWRhZWM0OTkzZTllMyIsInByb2R1Y3RzIjpbIklERU5USVRZIiwiUFVCTElTSF9DT05URU5UIiwiSURFTlRJVFlfQVVESUVOQ0UiLCJBQ1RJVklUWSIsIkVOR0FHRU1FTlQiLCJJTkNPTUUiLCJFTkdBR0VNRU5UX0FVRElFTkNFIl0sImlzcyI6Imh0dHBzOi8vYXBpLmdldHBoeWxsby5jb20iLCJhdWQiOiJodHRwczovL2FwaS5nZXRwaHlsbG8uY29tL3YxL2ludGVybmFsIiwiaWF0IjoxNjk2OTE5MDUwLjMxNTU0NiwiZXhwIjoxNjk3NTIzODUwLjMxNTUzN30.i12S2pVx-Ktvk6OY66R6TS6Edd7xCZguh7_Ur2ax1eI',
          token: token.data.sdk_token,
        };
        phyllo(obj);
        this.loader = false;
      });
    });

    return;
  }
}
