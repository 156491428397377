<div>
  <app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
  <app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
  <app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
  <app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
  <app-sidenavheader></app-sidenavheader>
</div>
<div>
  <app-loader *ngIf="realatedLoader"></app-loader>

  <div class="main">
    <div class="container-fluid pt-md-4">
      <div class="product_details mt-3 ml-md-3">
        <p class="ml-md-2 pointer" (click)="goBack()">Go Back</p>
        <div class="row">
          <div class="col-md-6 col-lg-8 moblie-view-padding">
            <div class="d-lg-flex" *ngFor="let collection of collectionDetails">
              <div class="main-img-collection mt-md-1">
                <a class="pointer" target="_blank">
                  <!-- <img src="{{
                              'https://img.youtube.com/vi/' +
                                collection.sourceURL.slice(collection.sourceURL.indexOf('=') + 1) +
                                '/hqdefault.jpg'
                            }}" class="c-img-1"> -->
                  <img
                    *ngIf="
                      collection.thumbNail != '' &&
                      collection.customThumbNail == null
                    "
                    src="{{ collection.thumbNail }}"
                    class="c-img-1"
                    loading="lazy"
                    alt="{{ collection.summaryName }}"
                  />
                  <img
                    *ngIf="
                      collection.customThumbNail != '' &&
                      collection.thumbNail == null
                    "
                    src="{{ collection.customThumbNail }}"
                    class="c-img-1"
                    loading="lazy"
                    alt="customThumbnNail"
                  />
                  <a>
                    <!-- <img src="../../assets/images/yt-1.png" class="youtube-icon" loading="lazy"> -->
                  </a>
                  <div class="r-s-img">
                    <img
                      (click)="shareIt()"
                      src="../../../assets/images/r-s.png"
                      class="r-s-b"
                    />
                  </div>
                  <app-loader *ngIf="loaderPreview"></app-loader>
                </a>
                <!-- <iframe  loading="lazy" class="loaders mt-md-4 pt-md-2" poster="../../assets/images/v-tag.png" [src]="
                            collection.sourceURL.replace('watch?v=', 'embed/') | safePipe" allow='autoplay' allowfullscreen></iframe> -->
                <!-- {{collection.reader[0]._id == userTypeId}} -->
                <div
                  class="d-flex"
                  *ngIf="collection?.reader[0]?._id == userTypeId"
                >
                  <a (click)="edit(collection._id)">
                    <img
                      class="edit mr-3"
                      loading="lazy"
                      src="../../../assets/images/edit.png"
                    />
                  </a>
                  <a data-toggle="modal" data-target="#deleteCollection">
                    <img
                      loading="lazy"
                      class="edit"
                      src="../../../assets/images/delete.png"
                    />
                  </a>
                </div>
                <!-- <div class="d-flex" *ngIf="collection.reader[0]._id == userTypeId"><a (click)="edit(collection._id)"><img class="edit mr-3" src="../../../assets/images/edit.png"></a><a data-toggle="modal" data-target="#deleteCollection"><img  class="edit" src="../../../assets/images/delete.png"></a></div> -->
                <!-- modal -->
                <div
                  class="modal fade"
                  data-keyboard="false"
                  data-backdrop="static"
                  id="deleteCollection"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="deleteCollectionTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img
                            src="../../assets/images/close-m.svg"
                            loading="lazy"
                          />
                        </button>
                      </div>
                      <div class="modal-body">
                        Are you sure you want to delete this blog?
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn back"
                          data-dismiss="modal"
                        >
                          No, take me back
                        </button>
                        <button
                          type="button"
                          class="btn confirm"
                          data-dismiss="modal"
                          (click)="delete(collection._id)"
                        >
                          delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- modal -->
              </div>
              <div
                class="collection-header-1 product_details_Paragraph ml-lg-3 ml-md-1 moblie-view-c"
              >
                <h2 class="moblie-source text-center">
                  source:This summary has been generated by the YouTube video
                </h2>
                <div class="d-flex hide">
                  <h4 class="mt-lg-4">{{ collection.summaryName }}</h4>
                  <div class="moblie-tweet">
                    <div
                      *ngIf="!collection.bookmarkSummary"
                      class="comment ml-4"
                      (click)="
                        saveCollection(
                          collection,
                          true,
                          collection._id,
                          collection.summaryName
                        )
                      "
                    >
                      <img src="../../../assets/images/h.png" class="img-b" />
                    </div>
                    <div
                      *ngIf="collection.bookmarkSummary"
                      class="comment ml-5"
                    >
                      <img
                        src="../../../assets/images/he-1.png"
                        class="img-b"
                      />
                    </div>
                  </div>
                </div>

                <!-- <p class="c-name ">
                  <span> {{collection.videoViewsCount}} Youtube views</span>
                </p> -->

                <!-- <button class="btn btn-dark" (click)="googleTranslateService(collection)">Translate</button> -->
                <div class="d-flex mt-2 hide">
                  <div class="channle-img">
                    <img
                      *ngIf="channelProfile == null"
                      src="../../assets/images/user-1.png"
                    />
                    <img
                      *ngIf="channelProfile != null"
                      [src]="channelProfile"
                      loading="lazy"
                    />
                  </div>
                  <div class="published-by mt-2 ml-2">
                    <h5>
                      Originally published by
                      <span
                        class="pointer"
                        (click)="channelNameRoute(channelname)"
                        >{{ channelname }}</span
                      >
                    </h5>
                  </div>
                  <div class="follow-category follow-btn-c mt-1 ml-2">
                    <button
                      *ngIf="!followPublisher"
                      class="cursor follow-btn"
                      (click)="followBtn(collection)"
                    >
                      Follow
                    </button>
                    <button
                      *ngIf="followPublisher"
                      class="cursor following-btn"
                      (click)="UnfollowBtn(channeluserId, collection)"
                    >
                      <span>Following</span>
                    </button>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="channle-img r-s-img">
                    <img [src]="channelProfile" loading="lazy" />
                  </div>
                  <div class="published-by mt-2 ml-2 r-s-img">
                    <h5>
                      <span
                        class="pointer sp"
                        (click)="channelNameRoute(channelname)"
                        >{{ channelname }}</span
                      >
                    </h5>
                  </div>
                  <div class="follow-category follow-btn-c mt-1 ml-2 r-s-img">
                    <button
                      *ngIf="!followPublisher"
                      class="cursor follow-btn"
                      (click)="followBtn(collection)"
                    >
                      Follow
                    </button>
                    <button
                      *ngIf="followPublisher"
                      class="cursor following-btn"
                      (click)="UnfollowBtn(channeluserId, collection)"
                    >
                      <span>Following</span>
                    </button>
                  </div>
                </div>
                <h4 class="mt-lg-4 moblie-source">
                  {{ collection.summaryName }}
                </h4>
                <!-- <div class="d-flex moblie-view-buttons">
                  <div>
                    <button type="submit" class="book-btn mt-md-3" (click)="saveCollection(collection._id,collection.summaryName)">
                      <img src="../../../../assets/images/bookmark.png" loading="lazy" class="book"> Bookmark </button>
                  </div>
                  <div>
                    <a type="submit" class="comment-btn mt-md-3 ml-2" (click)="scrollToTarget()"> Post a comment </a>
                  </div>
                  <div class="tweet mt-md-3 ml-md-2">
                    <a [href]="tweetBtn()" target="_blank">Tweet this! <img src="../../assets/images/tw.png">
                    </a>
                  </div>
                
                </div>
                <!-- <div class="productCount-p"><h6>{{prooductCount}} product links available in this video below</h6></div> -->
                <p *ngIf="bookSaved" class="succesfully mt-3">
                  Bookmark successfully saved
                </p>
                <p *ngIf="CollectionError != null" class="code-d-c mt-md-3">
                  {{ CollectionError }}
                </p>
              </div>
            </div>
            <div class="summary-section moblie-view-c">
              <div class="summary-header">
                <!-- <h3>
                  <span> Summery of the video powered by AI</span>
                </h3> -->
              </div>
              <div class="summary">
                <div class="d-flex"></div>
                <!-- <div class="swiper mySwiper" ><div class="swiper-wrapper"><div class="swiper-slide" ><h3  >{{summary[0]}}</h3></div><div class="swiper-slide" #screen><h3 >{{summary[1]}}</h3></div><div class="swiper-slide" #screen><h3>{{summary[2]}}</h3></div><div class="swiper-slide"><h3>{{summary[3]}}</h3></div><div *ngIf="summary[4] != null" class="swiper-slide"><h3>{{summary[4]}}</h3></div></div></div> -->
                <div class="text-center" *ngIf="summary == ''">
                  <p class="text-center">Summary not available</p>
                </div>
                <!-- <div  [innerHTML]="summaryTranslate" class="summary-text mb-3">{{summaryTranslate}}</div> -->
                <div class="summary-data scroll">
                  <div
                    *ngIf="editorView"
                    [innerHTML]="summaryEditorView"
                    class="summary-text mb-3"
                  ></div>
                  <!-- <div  [innerHTML]="summaryEditorView"  class="summary-text mb-3"></div> -->
                  <div *ngIf="exsitingCollection">
                    <div *ngFor="let Summary of summary; index as i">
                      <div class="d-flex summaryView mb-3">
                        <h4 [class.blue]="i == 0">{{ i }}</h4>
                        <p class="">
                          {{ Summary }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="summary-download"
                  *ngIf="
                    this.attachementFile != undefined &&
                    this.attachementFile != ''
                  "
                >
                  <button (click)="downloadSummary()">Download summary</button>
                </div>

                <div class="card-section-Summary">
                  <div
                    class="card"
                    *ngFor="let collection of collectionDetails"
                  >
                    <!-- <div class="card-body">
                       <div class="channel-card pl-md-1 pl-md-3">
                      <div class="d-flex jus">
                        <div class="channel-avatar-section mr-3">
                          <div class="d-flex">
                            <div class="avartar">
                              <img *ngIf="collection.publisherImage == null" src="../../../assets/images/user-1.png" class="user-img">
                              <img  *ngIf="collection.publisherImage != null"  src="{{collection?.publisherImage}}" class="user-img">
                            </div>
                            <div class="channel-details">
                              <h4>{{collection?.publisherName}}</h4>
                               
                              <a  class="subscribed" *ngIf="!followPublisher" (click)="subscribeChannel(collection,true)">Follow</a>
                              <a  class="subscribed" *ngIf="followPublisher">Followed</a>
                            </div>
                          </div>
                        </div>
                        <div>
                        
                     <div >
                     <div  class='category-follow mt-2 mr-3 ml-md-4' *ngFor="let categories of collection.topics.slice(0,1)">
                          <div class="d-flex ">
                            <div *ngIf="!categories.topicsFollow" class="catergory-details">
                              <h6 (click)="categoryNavigate()" class="mb-md-2">Join {{categories.name}} community</h6>
                              <a (click)="followCollectionCatergory(categories,true,collection)"> join </a>
                            </div>
                            <div *ngIf="categories.topicsFollow" class="catergory-details-1">
                              <h6 (click)="categoryNavigate()" class="mb-md-2">{{categories.name}} community</h6>
                              <a (click)="unFollowCategories(categories,true,collection)"><span class="glyphicon glyphicon-">Joined</span></a>
                            </div>
                          </div>
                        </div>
                     </div>
                     
                        </div>
                        
                         

                      </div>
                    </div>
                     </div> -->

                    <!-- <div class="share-section pt-md-3 ">
                       <div class="d-md-flex hide">
                         <div  class='category-follow mt-md-4 mr-md-3 ml-md-4' *ngFor="let categories of collection.topics.slice(0,1)">
                          <div class="d-flex ">
                            <div *ngIf="!categories.topicsFollow" class="catergory-details">
                              <div class="d-flex">
 
<h6  (click)="categoryNavigate()" class="mb-md-2"   >Want to read more summaries on <span class="text-decoration-underline">{{categories.name}}</span>? join {{categories.name}} community</h6>
                              <a (click)="followCollectionCatergory(categories,true,collection)"> join </a>
                              </div>
                              
                            </div>
                            <div *ngIf="categories.topicsFollow" class="catergory-details-1 d-flex">

                              <h6 (click)="categoryNavigate()" class="mb-md-2">Great, you are following <span class="text-decoration-underline">{{categories.name}}</span> topic</h6>
                              <a (click)="unFollowCategories(categories,true,collection)"><span class="glyphicon glyphicon-">unfollow</span></a>
                            </div>
                          </div>
                        </div>
                         <div class="d-flex ml-auto justify-content-around space_view media-screen-flex">

                        <div class="thumps-up-image pointer space">
                         
                          <button class="upVote" *ngIf="!collection.summaryLike" (click)="likeBtn(collection,true,collection._id)">
                            <img src="../../../assets/images/like012.png">
                            <p id="upvote "  >
                             
                              
                              like 
                             
                            </p>
                          </button>
                          
                          <button class="upVote" *ngIf="collection.summaryLike">
                              <img src="../../../assets/images/like012.png">
                               
                            <p id="upvote" class="d-flex Blinked"> liked
                             
      </p>
                          </button>
                        </div>
                       

                        <div class="space">
                         <div *ngIf="!collection.bookmarkSummary" class="comment" (click)="saveCollection(collection,true,collection._id,collection.summaryName)">
                          <img src="../../../assets/images/bookmark.png">
                          <p class="bookmark">Bookmark</p>
                        </div>
                        <div *ngIf="collection.bookmarkSummary" class="comment">
                          <img src="../../../assets/images/bookmark.png">
                          <p>bookmarked</p>
                        </div>
                        </div>
                       
                      </div>
                       </div>
                     
                    </div> -->
                    <!-- NEW-COMMENT-SECTION -->
                    <div class="d-flex mx-md-5 mx-3 my-md-4 my-2">
                      <div class="d-md-flex">
                        <div>
                          <img
                            src="../../../assets/images/vote.png"
                            width="50px"
                          />
                          <p>Vote this summary</p>
                        </div>

                        <div
                          *ngFor="
                            let categories of collection.topics.slice(0, 1)
                          "
                        >
                          <div
                            class="category-follow mt-md-4 mr-md-3 mt-3 ml-md-4"
                            *ngIf="!categories.topicsFollow"
                          >
                            <div class="d-flex">
                              <div
                                *ngIf="!categories.topicsFollow"
                                class="catergory-details"
                              >
                                <div class="d-flex">
                                  <h6
                                    (click)="categoryNavigate()"
                                    class="mb-md-2"
                                  >
                                    Want to read more summaries on
                                    <span class="text-decoration-underline">{{
                                      categories.name
                                    }}</span
                                    >? join {{ categories.name }} community
                                  </h6>
                                  <a
                                    (click)="
                                      followCollectionCatergory(
                                        categories,
                                        true,
                                        collection
                                      )
                                    "
                                  >
                                    join
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ml-auto">
                        <button
                          mat-icon-button
                          [matMenuTriggerFor]="menu"
                          aria-label="Example icon-button with a menu"
                        >
                          <mat-icon>
                            <img
                              src="../../assets/images/dots.png"
                              class="report"
                              width="35px"
                            />
                            <!-- <p class="report-text">report a problem</p> -->
                          </mat-icon>
                        </button>
                        <mat-menu
                          #menu="matMenu"
                          class="mat-menu"
                          xPosition="after"
                        >
                          <button mat-menu-item class="mat-item-span" 
                              (click)="
                                saveCollection(
                                  collection,
                                  true,
                                  collection._id,
                                  collection.summaryName
                                )
                              ">
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon feather-bookmark.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Bookmark</span>
                          </button>
                          <button mat-menu-item class="mat-item-span"(click)="link()"
                              cdkCopyToClipboard="{{ url }}/c/{{
                                collection?.summaryName?.split(' ')?.join('-')
                              }}/id-{{ collection._id }}">
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon feather-link-2.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Copy link</span>
                          </button>
                          <button mat-menu-item class="mat-item-span">
                            <mat-icon>
                              <img
                                src="../../../assets/images/not-equal.png"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Not interested in this</span>
                          </button>
                          <button mat-menu-item class="mat-item-span">
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon feather-delete.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Unfollow {{ topicsActive }}</span>
                          </button>
                          <button mat-menu-item class="mat-item-span" (click)="UnfollowBtn(channeluserId, collection)"  *ngIf="followPublisher">
                            <mat-icon>
                              <img
                                src="../../../assets/images/sad.png"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Unfollow {{ channelname }}</span>
                          </button>
                            <button
                          mat-menu-item
                          class="mat-item-span"
                          (click)="
                            dislikeSummary(collection, true, collection._id)
                          "
                        >
                          <mat-icon>
                            <img
                              src="../../../assets/images/not-equal.png"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Downvote</span>
                        </button>
                          <button mat-menu-item class="mat-item-span"(click)="share(collection)">
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon awesome-share-alt.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Share with a friend </span>
                          </button>
                          <button mat-menu-item class="mat-item-span"(click)="report(collection)">
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon material-report-problem.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Report a problem</span>
                          </button>
                          <button mat-menu-item class="mat-item-span">
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon zocial-email.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Contact us</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                    <div class="new_comment_section">
                      <div class="d-flex">
                        <div class="comment_content">
                          <p>
                            This summary has been generated by AI from the
                            <a
                              class="text-decoration-underline"
                              target="_blank"
                              [href]="collection.video"
                              >Youtube video</a
                            >
                          </p>
                          <h4>
                            Comment this Summary
                            <a class="text-decoration-underline">link </a> in
                            the Youtube video and help others in youtube
                            community read this summary
                          </h4>
                        </div>
                        <div
                          class="Comment_img ml-auto cursor"
                          (click)="writeComment(collection)"
                        >
                          <img src="../../assets/images/comment.png" />
                          <p>Write Comment</p>
                        </div>
                      </div>
                    </div>
                    <!-- NEW-COMMENT-SECTION -->
                  </div>
                </div>
              </div>
            </div>

            <div class="share_now mt-5 pt-2 moblie-view-c hide" id="targetDiv">
              <p class="pb-2">
                Did you find this summary useful? Share it with friends
              </p>
              <div class="shareItBtn_Section d-lg-flex justify-content-center">
                <h6 class="tab-web">{{ shareLink | slice : 0 : 70 }}</h6>
                <button id="product-related-moblie" type="button" class="share">
                  {{ shareLink | slice : 0 : 44 }}
                </button>
                <button id="tab" type="button" class="share">
                  {{ shareLink | slice : 0 : 44 }}
                </button>
                <!-- <h6 ></h6> -->
                <button type="button" class="pointer" (click)="shareIt()">
                  Share it
                </button>
              </div>
            </div>
            <!-- /* comments section */ -->
            <div
              class="comments-section ml-md-5 pl-2 pb-4 mt-4 pt-3"
              *ngIf="showComments"
            >
              <h5 class="total_comments">{{ totalComments }} Comments</h5>
              <div class="comment-header">
                <div class="d-flex">
                  <div class="comment-img">
                    <img src="{{ metaImage }}" />
                  </div>
                  <div class="comment-input-field ml-md-3">
                    <form [formGroup]="commentForm">
                      <input
                        [readOnly]="readonly"
                        (keyup)="nameValidations($event, 'title')"
                        class="comment-form-input"
                        [class.inputActive]="inputActive == true"
                        formControlName="title"
                        (focus)="toggleButtons()"
                        (click)="commentEvent()"
                      />
                      <div
                        *ngIf="commentFormError.title.errors?.maxlength"
                        class="invalid-feedback m-t d-block"
                      >
                        Only 1000 characters are allowed
                      </div>
                      <div
                        class="d-flex mt-3 justify-content-end comment-add_form"
                        *ngIf="showButtons"
                      >
                        <button
                          class="cancel-comment mr-md-3 cursor"
                          (click)="cancelComment()"
                        >
                          Cancel
                        </button>
                        <button
                          class="comment-form-button cursor"
                          [disabled]="commentForm.invalid"
                          type="submit"
                          (click)="onSubmitComment()"
                        >
                          Comment
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                class="comments-user-added mt-4"
                *ngFor="let comments of commentUser"
              >
                <div class="d-flex">
                  <div class="comment-user-img">
                    <img
                      *ngIf="comments.reader[0].profile == null"
                      src="../../../assets/images/pr-img.jpeg"
                    />
                    <img
                      *ngIf="comments.reader[0].profile != null"
                      src="{{ comments.reader[0].profile }}"
                    />
                  </div>
                  <div class="user-comments-data ml-md-3">
                    <div class="d-flex">
                      <h4>@{{ comments.reader[0].name }}</h4>
                      <p class="ml-2">{{ comments.createdAt | datepipe }}</p>
                    </div>
                    <h6>{{ comments.comment }}</h6>

                    <!-- EDIT-COMMENT-FORM-SECTION -->
                    <div
                      class="comment-input-field ml-md-3"
                      *ngIf="comments.isEdit"
                    >
                      <form [formGroup]="editCommentForm">
                        <input
                          [readOnly]="readonly"
                          (keyup)="nameValidations($event, 'edit')"
                          class="comment-form-input"
                          [class.inputActive]="editActive == true"
                          formControlName="edit"
                          (focus)="toggleEditButtons()"
                          (click)="commentEvent()"
                        />
                        <div
                          class="d-flex mt-3 justify-content-end"
                          *ngIf="showEditButtons"
                        >
                          <button
                            class="cancel-comment mr-md-3 cursor"
                            (click)="cancelEditComment(comments)"
                          >
                            Cancel
                          </button>
                          <button
                            class="comment-form-button cursor"
                            [disabled]="editCommentForm.invalid"
                            type="submit"
                            (click)="saveEditForm(comments)"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                    <!-- EDIT-COMMENT-FORM-SECTION -->
                    <div class="d-flex">
                      <a
                        class="reply mr-md-3 cursor mr-2"
                        (click)="replyShowInput(comments)"
                      >
                        <h3>Reply</h3>
                      </a>
                      <a
                        class="reply cursor mr-md-3 mr-2"
                        *ngIf="comments.reader[0]._id == userTypeId"
                        (click)="editMainComment(comments)"
                      >
                        <h3>Edit</h3>
                      </a>
                      <a
                        class="reply cursor"
                        (click)="deletComment(comments)"
                        *ngIf="comments.reader[0]._id == userTypeId"
                      >
                        <h3>Delete</h3>
                      </a>
                    </div>
                  </div>
                  <div class="ml-md-auto mat-menu-collection">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu
                      #menu="matMenu"
                      class="mat-menu"
                      yPosition="above"
                    >
                      <button
                        mat-menu-item
                        class="mat-item-span"
                        (click)="spamComment(comments)"
                      >
                        <mat-icon
                          ><img
                            src="../../../assets/images/red-flag.png"
                            class="mr-3 spam-img"
                        /></mat-icon>
                        <span>Mark as Spam</span>
                      </button>
                      <button
                        mat-menu-item
                        class="mat-item-span"
                        (click)="spamOffensive(comments)"
                      >
                        <mat-icon
                          ><img
                            src="../../../assets/images/sad.png"
                            class="mr-3 spam-img"
                        /></mat-icon>
                        <span>Mark as Offensive</span>
                      </button>
                      <button
                        mat-menu-item
                        class="mat-item-span"
                        *ngIf="collectionUserId == userTypeId"
                        (click)="deleteCommentPublisherandPowerUser(comments)"
                      >
                        <mat-icon
                          ><img
                            src="../../../assets/images/de.png"
                            class="mr-3 spam-img"
                        /></mat-icon>
                        <span>Delete Comment</span>
                      </button>
                    </mat-menu>
                  </div>

                  <!-- <div class="comment-spam-img d-flex ml-md-auto">
                    <a (click)="spamComment(comments)"><img  src="../../../assets/images/spam (1).png" class="mr-3"></a>
                    <a (click)="spamOffensive(comments)"><img  src="../../../assets/images/report (1).png"></a>
                  </div> -->
                </div>
                <div
                  class="comment-header ml-md-5 pl-md-3 mt-md-3"
                  *ngIf="comments.showReplyForm"
                >
                  <div class="d-flex">
                    <div class="comment-img">
                      <img src="../../../assets/images/user-1.png" width="50" />
                    </div>
                    <div class="comment-input-field ml-md-3">
                      <form [formGroup]="replyCommentForm">
                        <input
                          class="comment-form-input"
                          (keyup)="nameValidations($event, 'reply')"
                          [class.inputActive]="inputActive == true"
                          formControlName="reply"
                          (focus)="toggleButtonsReply()"
                          (input)="commentEventReply($event)"
                        />
                        <div
                          *ngIf="replyCommentFormError.reply.errors?.maxlength"
                          class="invalid-feedback m-t d-block"
                        >
                          Only 1000 characters are allowed
                        </div>
                        <div
                          class="d-flex mt-3 justify-content-end"
                          *ngIf="showButtonsReply"
                        >
                          <button
                            class="cancel-comment mr-md-3 cursor"
                            (click)="cancelCommentReply(comments)"
                          >
                            Cancel
                          </button>
                          <button
                            class="cursor comment-form-button"
                            [disabled]="replyCommentForm.invalid"
                            type="submit"
                            (click)="onReplyComment()"
                          >
                            Reply
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <a
                  class="ml-5 pl-md-3 pl-2 replycount"
                  *ngIf="comments.commentReply.length != 0"
                  (click)="showReplyBtn(comments)"
                  >{{ comments.commentReply.length }} reply</a
                >
                <div *ngIf="comments.showReplys">
                  <div
                    class="comments-user-added mt-md-4 mt-3 ml-md-5"
                    *ngFor="let reply of comments.commentReply"
                  >
                    <div class="d-flex">
                      <div class="comment-user-img">
                        <img
                          *ngIf="reply.profile == null"
                          src="../../../assets/images/pr-img.jpeg"
                        />
                        <img
                          *ngIf="reply.profile != null"
                          src="{{ reply.profile }}"
                        />
                      </div>
                      <div class="user-comments-data ml-md-3">
                        <div class="d-flex">
                          <h4>@{{ reply.name }}</h4>
                          <p class="ml-2">{{ reply.createdAt | datepipe }}</p>
                        </div>
                        <h6>{{ reply.comment }}</h6>
                        <!-- reply-COMMENT-FORM-SECTION -->
                        <div
                          class="comment-input-field ml-md-3"
                          *ngIf="reply.isEdit"
                        >
                          <form [formGroup]="replyEditCommentForm">
                            <input
                              [readOnly]="readonly"
                              class="comment-form-input"
                              (keyup)="nameValidations($event, 'replyEdit')"
                              [class.inputActive]="editActive == true"
                              formControlName="replyEdit"
                              (focus)="toggleReplyEditButtons()"
                              (click)="commentEvent()"
                            />
                            <div
                              class="d-flex mt-3 justify-content-end"
                              *ngIf="showReplyEditButtons"
                            >
                              <button
                                class="cancel-comment mr-md-3 cursor"
                                (click)="cancelEditComment(reply)"
                              >
                                Cancel
                              </button>
                              <button
                                class="comment-form-button cursor"
                                [disabled]="replyEditCommentForm.invalid"
                                type="submit"
                                (click)="saveReplyEditForm(comments)"
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                        <!-- reply-COMMENT-FORM-SECTION -->
                        <div class="d-flex">
                          <a
                            class="reply cursor mr-3"
                            *ngIf="reply.readerId == userTypeId"
                            (click)="editReplyComment(reply)"
                          >
                            <h3>Edit</h3>
                          </a>
                          <a
                            class="reply cursor"
                            (click)="deletReplyComment(comments, reply._id)"
                            *ngIf="reply.readerId == userTypeId"
                          >
                            <h3>Delete</h3>
                          </a>
                        </div>
                      </div>
                      <div class="ml-md-auto mat-menu-collection">
                        <button
                          mat-icon-button
                          [matMenuTriggerFor]="menu"
                          aria-label="Example icon-button with a menu"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu
                          #menu="matMenu"
                          class="mat-menu"
                          yPosition="above"
                        >
                          <button
                            mat-menu-item
                            class="mat-item-span"
                            (click)="spamReplyComment(comments, reply._id)"
                          >
                            <mat-icon
                              ><img
                                src="../../../assets/images/red-flag.png"
                                class="mr-3 spam-img"
                            /></mat-icon>
                            <span>Mark as Spam</span>
                          </button>
                          <button
                            mat-menu-item
                            class="mat-item-span"
                            (click)="spamReplyOffensive(comments, reply._id)"
                          >
                            <mat-icon
                              ><img
                                src="../../../assets/images/sad.png"
                                class="mr-3 spam-img"
                            /></mat-icon>
                            <span>Mark as Offensive</span>
                          </button>
                          <button
                            mat-menu-item
                            class="mat-item-span"
                            (click)="
                              deleteReplyCommentPublisherandPowerUser(
                                comments,
                                reply._id
                              )
                            "
                            *ngIf="collectionUserId == userTypeId"
                          >
                            <mat-icon
                              ><img
                                src="../../../assets/images/de.png"
                                class="mr-3 spam-img"
                            /></mat-icon>
                            <span>Delete Comment</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /* comments section */ -->
          </div>
          <div class="col-md-6 col-lg-4">
            <div
              class="reaalted_publisher mt-3"
              *ngIf="recommendedPublishers != ''"
            >
              <div class="card">
                <div class="card-body">
                  <h5>Trending Publishers in {{ topicsActive }}</h5>
                  <div
                    class="d-flex mt-3"
                    *ngFor="let item of recommendedPublishers; let i = index"
                  >
                    <div class="img-publisher">
                      <img src="{{ item.publisherImage }}" />
                    </div>
                    <div class="publisher_name ml-3">
                      <h6>{{ item.name.slice(0, 14) }}</h6>
                    </div>
                    <div class="publisher_subscribe ml-auto">
                      <a
                        *ngIf="!item.followpublisher"
                        (click)="followPublisherBtn(item)"
                        >Subscribe</a
                      >
                      <a *ngIf="item.followpublisher">Subscribed</a>
                      <img
                        (click)="closePubliser(i)"
                        src="../../assets/images/close.png"
                        id="close_img_p"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="realted-collection" id="realated-collection">
              <p>Recommended Summaries</p>
              <div class="card">
                <div
                  class="text-center mt-md-4"
                  *ngIf="collectionRealtedData == ''"
                >
                  <p class="text-center">Related collections not available</p>
                </div>
                <div class="card-body" *ngIf="collectionRealtedData != ''">
                  <div
                    class="d-flex py-2"
                    *ngFor="let card of collectionRealtedData"
                  >
                    <div
                      class="related-c-img pointer"
                      (click)="collectionChange(card.summaryName, card._id)"
                    >
                      <!-- <img src="{{
                              'https://img.youtube.com/vi/' +
                                card.sourceURL.split('=').pop() +
                                '/hqdefault.jpg'
                            }}"> -->
                      <img
                        *ngIf="
                          card.thumbNail != '' && card.customThumbNail == null
                        "
                        src="{{ card.thumbNail }}"
                        class="c-img-1"
                        loading="lazy"
                        alt="{{ card.summaryName }}"
                      />
                      <img
                        *ngIf="
                          card.customThumbNail != '' && card.thumbNail == null
                        "
                        src="{{ card.customThumbNail }}"
                        class="c-img-1"
                        loading="lazy"
                        alt="customThumbnNail"
                      />
                    </div>
                    <div
                      class="related-c-header product_details_Paragraph ml-md-3"
                    >
                      <h4
                        class="mb-md-1 pointer"
                        (click)="collectionChange(card.summaryName, card._id)"
                      >
                        {{ card.summaryName }}
                      </h4>
                      <p class="c-name mt-md-2 pointer">
                        <a
                          class="pointer"
                          (click)="channelNameRoute(card.publisherName)"
                          >{{ card.publisherName }}</a
                        >
                      </p>
                      <h2 class="c-name mt-md-2">
                        Video views:{{
                          card.videoViewsCount ? card.videoViewsCount : "N/A"
                        }}
                      </h2>
                      <!-- <p>Products and Discount codes shared by the creator in this video</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="list-of-products mt-md-3 pt-md-3" id="product-related-moblie"><h1 class="py-md-3" *ngIf="collectionCreatedByCreator != ''">Products used in this video by the creator
              </h1><div class="c-p" *ngIf="collectionCreatedByCreator == ''"><h4>No products found in this collection</h4></div><ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                (init)="slickInit($event)"><div ngxSlickItem *ngFor="let cardDetails of collectionCreatedByCreator" class="slide"><div class="card mb-md-4"><div class="card-body pointer"><div class="product_list"><img *ngIf="cardDetails?.image  != null" loading="lazy" src="{{cardDetails?.image}}"
                          class="code-1-img image" (click)="productDetails(cardDetails.productTitle,cardDetails._id)"><img *ngIf="cardDetails?.productImage != ''" loading="lazy"
                          src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img"
                          (click)="productDetails(cardDetails.productTitle,cardDetails._id)"><div class="d-flex"><div class="mr-auto"><p class="header-p pt-2">{{cardDetails.productTitle | slice:0:24}}</p></div></div><div class="d-flex"><div class="mr-auto collection-name-link ml-3"><a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}"><h5>View Product</h5></a></div><div class="save-for-later d-flex  mr-3"><div><img src="../../../assets/images/heart.svg" loading="lazy"
                                *ngIf="!cardDetails.isBookmark"><img src="../../../assets/images/heart-1.png" loading="lazy"
                                *ngIf="cardDetails.isBookmark"></div><div><a (click)="saveForlater(cardDetails._id,cardDetails,cardDetails.isBookmark)"><h5 *ngIf="!cardDetails.isBookmark">Bookmark</h5></a><a><h3 class="saved-b" *ngIf="cardDetails.isBookmark">Bookmarked</h3></a></div></div></div></div><div class="product_list_details"><div class="d-flex"></div><div class="details-List-view" *ngIf="cardDetails.couponCode != ''"><p>Creator Discount Code Available</p><div class="creator-code"><div class="d-flex code-d"><div class="code-p"><p>******</p></div><div class="copy-btn ml-auto"><button type="button" class="ml-auto" (click)="showCoupon(cardDetails._id,cardDetails.isBookmark)">Show</button></div></div></div></div></div></div></div></div></ngx-slick-carousel></div> -->
          </div>
        </div>
        <div class="collection-header product_details_Paragraph">
          <!-- <div class="list-of-products mt-md-3 pt-md-3" id="product-related"><h1 *ngIf="collectionCreatedByCreator != ''" class="py-md-3">Products used in this video by the creator</h1><div class="c-p mt-md-5" *ngIf="collectionCreatedByCreator == ''"><h4>No products found in this collection</h4></div><div class="row" *ngIf="collectionCreatedByCreator != ''"><div class="col-lg-5 col-md-4" *ngFor="let cardDetails of collectionCreatedByCreator;index as i"><div class="d-flex mx-2 mb-1" *ngIf="cardDetails.userId == userTypeId"><a (click)="editProduct(cardDetails._id)"><img class="edit mr-3" src="../../../assets/images/edit.png"
                      loading="lazy"></a><a data-toggle="modal" data-target="#deleteProducts"><img class="edit"
                      src="../../../assets/images/delete.png"></a></div><div class="modal fade" data-keyboard="false" data-backdrop="static" id="deleteProducts" tabindex="-1"
                  role="dialog" aria-labelledby="deleteCollectionTitle" aria-hidden="true"><div class="modal-dialog modal-dialog-centered" role="document"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><img src="../../assets/images/close-m.svg" loading="lazy"></button></div><div class="modal-body">Are you sure you want to delete this product ? </div><div class="modal-footer"><button type="button" class="btn back" data-dismiss="modal">No, take me back</button><button type="button" class="btn confirm" data-dismiss="modal"
                          (click)="deleteProduct(cardDetails._id)">delete</button></div></div></div></div><div class="card mb-md-4"><div class="card-body pointer"><div class="product_list"><img *ngIf="cardDetails?.image != null "
                        (click)="productDetails(cardDetails.productTitle,cardDetails._id)" src="{{cardDetails?.image}}"
                        class="code-1-img image" loading="lazy"><img *ngIf="cardDetails?.productImage != ''"
                        (click)="productDetails(cardDetails.productTitle,cardDetails._id)"
                        src="{{cardDetails?.productImage[0]?.url}}" class="code-1-img" loading="lazy"><div class="d-flex"><div class="mr-auto"><p class="header-p pt-2">{{cardDetails.productTitle | slice:0:22}}</p></div></div><div class="d-flex"><div class="mr-auto collection-name-link ml-lg-3"><a routerLink="/p/{{cardDetails.productTitle.split(' ').join('-')}}/id-{{cardDetails._id}}"><h5>View Product</h5></a></div><div class="save-for-later d-flex  mr-3"><div><img src="../../../assets/images/heart.svg" *ngIf="!cardDetails.isBookmark" loading="lazy"><img src="../../../assets/images/heart-1.png" *ngIf="cardDetails.isBookmark" loading="lazy"></div><div><a (click)="saveForlater(cardDetails._id,cardDetails,cardDetails.isBookmark)"><h5 *ngIf="!cardDetails.isBookmark">Bookmark</h5></a><a><h3 class="saved-b" *ngIf="cardDetails.isBookmark">Bookmarked</h3></a></div></div></div></div><div class="product_list_details"><div class="d-flex"></div><div class="details-List-view" *ngIf="cardDetails.couponCode != ''"><p>Creator Discount Code Available</p><div class="creator-code"><div class="d-flex code-d"><div class="code-p"><p>******</p></div><div class="copy-btn ml-auto"><button type="button" class="ml-auto" (click)="showCoupon(cardDetails._id,cardDetails.isBookmark)">Show</button></div></div></div></div></div></div></div></div></div></div> -->
          <div
            class="realted-collection mb-3 mt-4"
            id="realated-collection-moblie"
          >
            <p>Related collections</p>
            <div class="card mb-4">
              <div
                class="text-center mt-md-4"
                *ngIf="collectionRealtedData == ''"
              >
                <p class="text-center">Related collections not available</p>
              </div>
              <div class="card-body" *ngIf="collectionRealtedData != ''">
                <div
                  class="d-flex py-2"
                  *ngFor="let card of collectionRealtedData"
                >
                  <div
                    class="related-c-img pointer"
                    (click)="collectionChange(card.summaryName, card._id)"
                  >
                    <img
                      *ngIf="
                        card.thumbNail != '' && card.customThumbNail == null
                      "
                      src="{{ card.thumbNail }}"
                      class="c-img-1"
                      loading="lazy"
                      alt="{{ card.summaryName }}"
                    />
                    <img
                      *ngIf="
                        card.customThumbNail != '' && card.thumbNail == null
                      "
                      src="{{ card.customThumbNail }}"
                      class="c-img-1"
                      loading="lazy"
                      alt="customThumbnNail"
                    />
                  </div>
                  <div
                    class="related-c-header product_details_Paragraph ml-md-3"
                  >
                    <h4
                      class="mb-md-1"
                      (click)="collectionChange(card.summaryName, card._id)"
                    >
                      {{ card.summaryName }}
                    </h4>
                    <p class="c-name mt-md-2 pointer">
                      <a (click)="channelNameRoute(card.publisherName)">{{
                        card.publisherName
                      }}</a>
                    </p>
                    <h2 class="c-name mt-md-2">
                      Video views:{{
                        card.videoViewsCount ? card.videoViewsCount : "N/A"
                      }}
                    </h2>
                    <!-- <p>Products and Discount codes shared by the creator in this video</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="subscribe_now mt-3 pt-2 mb-3"  *ngIf = "sessionType == 'user' || sessionType == 'home'"><p class="pb-2">Get the best out of Summarizly AI every week</p><form [formGroup]="subscribeForm"><div class="subscribe d-flex justify-content-center"><div><input formControlName="email"  placeholder="Enter your email address"><div *ngIf="c.email.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Email is required </div><div *ngIf="error != null" class="invalid-feedback m-t d-block">{{error}}</div></div><div><button type="button" (click)="subscribe()" class="pointer">Subscribe</button></div><app-loader class="theme-blue" *ngIf="emailSubcribe"></app-loader></div></form></div> -->
    </div>
  </div>
  <app-footer></app-footer>
</div>
