// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  AUTH_URL: 'https://back-end-apis-production-17d3.up.railway.app/v1/',
  COLLECTION_URL: ' https://back-end-apis-production-c8f4.up.railway.app/v1/',
  USER: 'https://back-end-apis-production-25be.up.railway.app/v1/',
  SHARE_URL: 'https://dev.summarizly.ai',
  URL: 'https://dev.summarizly.ai',
  ADMIN_URL: 'https://back-end-apis-production-f84e.up.railway.app/v1/',
  EMAILAPIURL: 'https://app.loops.so/api/v1/contacts/create',
  MIX_PANEL_ID: '0e14cc5b81a73d6e12e403f8a2492d4d',
  CLEVERTAP: false,
  APICALL: false,
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
