<app-homenavbar *ngIf="sessionType == 'home'"></app-homenavbar>
<app-usernavbar *ngIf="sessionType == 'user'"></app-usernavbar>
<app-navbar *ngIf="sessionType == 'powerUser'"></app-navbar>
<app-creatornavbar *ngIf="sessionType == 'publisher'"></app-creatornavbar>
<main>
  <!-- <button class="btn btn-primary" (click)="getData()">Get youtube data</button> -->
  <!-- <button class="btn btn-primary" (click)="getData()">Get youtube data</button> -->
  <app-loader *ngIf="!collectionList"></app-loader>
  <app-loader *ngIf="catergoryLoad"></app-loader>
  <div class="main" *ngIf="this.isBrowser">
    <div class="container-fluid">
      <div class="collectionall-header pt-md-5">
        <div class="container">
          <div class="paragraph" *ngIf="animationsView">
            <h1 class="about-title">A place for you to grow your knowledge on in </h1>
            <div class="rotating-words-container" *ngIf="isBrowser">
              <ng-container
                *ngFor="let word of categoryListAnimation; let i = index"
              >
                <span class="word" *ngIf="currentIndex === i">{{
                  word.name
                }}</span>
              </ng-container>
            </div>
            <h1>in 200 words</h1>
          </div>
          <div class="wrapper" *ngIf="noAnimationsView">
            <h1 class="about-title">A place for you to grow your knowledge on in </h1>
            <div class="words">
              <p>
                <span>
                  {{ activeState }}
                </span>
              </p>
            </div>
            <h1>in 200 words</h1>
          </div>
          <div class="d-md-flex scroll mb-md-5 web-page">
            <a
              class="category-btns pointer"
              *ngFor="let items of categoryList"
              (click)="navClick(items, 'resposive')"
              [class.all]="items.name == activeState"
            >
              {{ items.name }}
            </a>
          </div>
          <div class="moblie-responsive">
            <div class="d-flex scroll mb-md-5">
              <a
                class="category-btns pointer"
                *ngFor="let items of categoryList"
                (click)="navClick(items, 'resposive')"
                [class.all]="items.name == activeState"
              >
                {{ items.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="no-collection" *ngIf="collectionList == ''">
            <h4>This topic contains no blogs.</h4>
          </div>
        </div>
        <div class="summary-section mt-3 web-page">
          <div class="row">
            <div class="col-lg-7 col-md-7">
              <div
                class="summary card mb-2"
                id="fullSummary"
                *ngFor="let collection of realatedcollectionList"
              >
                <div class="card-body">
                  <div class="d-flex summary-p">
                    <div class="summary-header ml-md-4 channel-details">
                      <div class="summary-start">
                        <div class="d-flex">
                          <!-- <div class="moblie-tweet"><div *ngIf="!collection.bookmarkSummary" class="comment ml-4" (click)="saveCollection(collection,true,collection._id,collection.summaryName)"><img src="../../../assets/images/h.png" class="img-b"></div><div *ngIf="collection.bookmarkSummary" class="comment ml-5"><img src="../../../assets/images/he-1.png" class="img-b"></div></div> -->
                          <a
                            href="/c/{{
                              collection?.summaryName?.split(' ')?.join('-')
                            }}/id-{{ collection._id }}"
                            (click)="collectionsClick(collection._id)"
                          >
                            <h3>{{ collection.summaryName }}</h3>
                          </a>
                          <!-- <div class="ml-md-auto mat-menu-collection">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon>
                                    <img src="../../assets/images/dots.png" class="report">
                              
                                  </mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="mat-menu" xPosition="after">
                                  <button mat-menu-item class="mat-item-span" (click)="report('Inaccurate',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/red-flag.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Inaccurate</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Spam',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/red-flag.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Spam</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Low quality',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/sad.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Low quality</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Offensive ',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/sad.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Offensive</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Translation error',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/sad.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Translation error</span>
                                  </button>
                                </mat-menu>
                              </div> -->
                        </div>
                        <img
                          *ngIf="
                            collection.thumbNail != '' &&
                            collection.customThumbNail == null
                          "
                          src="{{ collection.thumbNail }}"
                          class="img"
                          loading="lazy"
                          alt="{{ collection.summaryName }}"
                        />
                        <img
                          *ngIf="
                            collection.customThumbNail != '' &&
                            collection.thumbNail == null
                          "
                          src="{{ collection.customThumbNail }}"
                          class="img"
                          loading="lazy"
                          alt="customThumbnNail"
                        />
                        <p class="views">
                          {{ collection.viewCount | records }} video views on
                          Youtube
                        </p>
                        <!-- <h6>Video source <a target="_blank" href="{{collection.video}}">Youtube</a></h6> -->
                        <div class="channel-avatar-section mr-5">
                          <div class="d-flex">
                            <div class="channel-details">
                              <a
                                (click)="
                                  navigateChannel(collection.publisherName)
                                "
                              >
                                <h4>
                                  Published by
                                  <span class="text-decoration-underline">{{
                                    collection?.publisherName
                                  }}</span>
                                </h4>
                              </a>
                            </div>
                            <div class="channel-details ml-md-3">
                              <a
                                class="subscribed_1"
                                *ngIf="!collection.followPublisher"
                                (click)="subscribeChannel(collection, true)"
                                >Follow</a
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          [class.active]="blogActive"
                          class="summary-paragrph summary-text"
                          innerHTML="{{ collection.Summary }}"
                        ></div>
                      </div>
                      <div class="mt-1 read-s">
                        <a
                          href="/c/{{
                            collection?.summaryName?.split(' ')?.join('-')
                          }}/id-{{ collection._id }}"
                          class="anchor-tag"
                          >Show more</a
                        >
                      </div>
                    </div>
                    <!-- <div class="report_problem"><img src="../../assets/images/warning (1).png"></div> -->
                  </div>
                  <!-- <div class="channel-card pl-md-5">
                        <div class="d-md-flex">
                          <div>
                            <div>
                              <div class='category-follow mt-2 mr-3' *ngFor="let categories of collection.topics | fliterCategorty: {name : activeState}">
                                <div class="d-flex ">
                                  <div *ngIf="!categories.topicsFollow" class="catergory-details">
                                    <div class="d-flex">
                                      <a (click)="catergoryNavigate(categories.name)">
                                        <h6 class="mb-md-2">Want to read more summaries on <span>{{categories.name}}</span>? join {{categories.name}} community </h6>
                                      </a>
                                      <a class="not-login_catergory" (click)="followBtn(categories,true,collection)">join</a>
                                    </div>
                                  </div>
                                  <div *ngIf="categories.topicsFollow" class="catergory-details-1">
                                    <div class="d-flex">
                                      <a (click)="catergoryNavigate(categories.name)">
                                        <h6 class="mb-md-2">Great, you are following <span>{{categories.name}} </span> topic </h6>
                                      </a>
                                      <a class="catergoryAnchorTag" (click)="unFollowCategories(categories,true,collection)">
                                        <span class="glyphicon glyphicon-">Following</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="share-section  ml-auto">
                            <div class="d-flex justify-content-md-around media-screen-flex">
                              <div class="comment" (click)="share(collection)">
                                <img src="../../../assets/images/send.png">
                                <p>share</p>
                              </div>
                              <div class="thumps-up-image pointer">
                                <button class="upVote" *ngIf="!collection.summaryLike" (click)="
                            likeCollections(collection,true,collection._id)">
                                  <img src="../../../assets/images/like012.png">
                                  <p id="upvote "> Like </p>
                                </button>
                                <button class="upVote" *ngIf="collection.summaryLike">
                                  <img src="../../../assets/images/like012.png">
                                  <p id="upvote" class=" Blinked"> liked </p>
                                </button>
                              </div>
                              <div class="">
                                <div *ngIf="!collection.bookmarkSummary" class="comment" (click)="saveCollection(collection,true,collection._id,collection.summaryName)">
                                  <img src="../../../assets/images/bookmark.png">
                                  <p class="bookmark">bookmark</p>
                                </div>
                                <div *ngIf="collection.bookmarkSummary" class="comment">
                                  <img src="../../../assets/images/bookmark.png">
                                  <p>bookmarked</p>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div> -->

                  <div class="d-flex mx-3 my-2">
                    <div class="d-md-flex">
                      <div class="d-flex">
                        <div>
                          <img
                            src="../../../assets/images/vote.png"
                            width="50px"
                            *ngIf="!collection.summaryLike"
                            (click)="
                              likeCollections(collection, true, collection._id)
                            "
                          />
                          <img
                            src="../../../assets/images/vote.png"
                            width="50px"
                            *ngIf="collection.summaryLike"
                          />
                        </div>

                        <div class="image-container cursor">
                          <img
                            src="../../assets/images/information.png"
                            class="img-book"
                          />
                          <p class="image-text">
                            If you like this summary you can vote for it
                          </p>
                        </div>
                      </div>

                      <div
                        *ngFor="
                          let categories of collection.topics
                            | fliterCategorty : { name: activeState }
                        "
                      >
                        <div
                          class="category-follow mt-md-4 mr-md-2 ml-md-2"
                          *ngIf="!categories.topicsFollow"
                        >
                          <div class="d-flex">
                            <div class="catergory-details">
                              <div class="d-flex">
                                <a (click)="catergoryNavigate(categories.name)">
                                  <h6 class="mb-md-2">
                                    Want to read more summaries on
                                    <span>{{ categories.name }}</span
                                    >? join {{ categories.name }} community
                                  </h6>
                                </a>
                                <a
                                  class="not-login_catergory"
                                  (click)="
                                    followBtn(categories, true, collection)
                                  "
                                  >join</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="ml-auto">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu1"
                        aria-label="Example icon-button with a menu"
                      >
                        <mat-icon>
                          <img
                            src="../../assets/images/dots.png"
                            class="report"
                            width="35px"
                          />
                          <!-- <p class="report-text">report a problem</p> -->
                        </mat-icon>
                      </button>
                      <mat-menu
                        #menu1="matMenu"
                        class="mat-menu"
                        xPosition="after"
                      >
                        <button
                          mat-menu-item
                          class="mat-item-span"
                          *ngIf="!collection.bookmarkSummary"
                          (click)="
                            saveCollection(
                              collection,
                              true,
                              collection._id,
                              collection.summaryName
                            )
                          "
                        >
                          <mat-icon>
                            <img
                              src="../../../assets/images/Icon feather-bookmark.svg"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Bookmark</span>
                        </button>

                        <button
                          mat-menu-item
                          class="mat-item-span"
                          (click)="link()"
                          cdkCopyToClipboard="{{ url }}/c/{{
                            collection?.summaryName?.split(' ')?.join('-')
                          }}/id-{{ collection._id }}"
                        >
                          <mat-icon>
                            <img
                              src="../../../assets/images/Icon feather-link-2.svg"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Copy link</span>
                        </button>
                        <button mat-menu-item class="mat-item-span">
                          <mat-icon>
                            <img
                              src="../../../assets/images/not-equal.png"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Not interested in this</span>
                        </button>
                        <button
                          mat-menu-item
                          class="mat-item-span"
                          (click)="
                            dislikeSummary(collection, true, collection._id)
                          "
                        >
                          <mat-icon>
                            <img
                              src="../../../assets/images/not-equal.png"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Downvote</span>
                        </button>
                        <ng-container
                          *ngFor="
                            let categories of collection.topics
                              | fliterCategorty : { name: activeState }
                          "
                        >
                          <button
                            mat-menu-item
                            class="mat-item-span"
                            (click)="
                              unFollowCategories(categories, true, collection)
                            "
                            *ngIf="categories.topicsFollow"
                          >
                            <mat-icon>
                              <img
                                src="../../../assets/images/Icon feather-delete.svg"
                                class="mr-3 spam-img"
                              />
                            </mat-icon>
                            <span>Unfollow {{ categories.name }}</span>
                          </button>
                        </ng-container>

                        <button
                          mat-menu-item
                          class="mat-item-span"
                          (click)="UnfollowBtn(collection, true)"
                          *ngIf="collection.followPublisher"
                        >
                          <mat-icon>
                            <img
                              src="../../../assets/images/sad.png"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Unfollow {{ collection.publisherName }}</span>
                        </button>
                        <button mat-menu-item class="mat-item-span">
                          <mat-icon>
                            <img
                              src="../../../assets/images/Icon awesome-share-alt.svg"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Share with a friend </span>
                        </button>
                        <button mat-menu-item class="mat-item-span">
                          <mat-icon>
                            <img
                              src="../../../assets/images/Icon awesome-share-alt.svg"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Dowload summary words</span>
                        </button>
                        <button mat-menu-item class="mat-item-span">
                          <mat-icon>
                            <img
                              src="../../../assets/images/Icon material-report-problem.svg"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Report a problem</span>
                        </button>
                        <button mat-menu-item class="mat-item-span">
                          <mat-icon>
                            <img
                              src="../../../assets/images/Icon zocial-email.svg"
                              class="mr-3 spam-img"
                            />
                          </mat-icon>
                          <span>Contact us</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>

                  <p *ngIf="!collection.summaryLike" class="like-web ml-2">
                    Vote this summary
                  </p>

                  <!-- <p *ngIf="collection.summaryLikeCount != 0 && collection.summaryLikeCount == true">{{ collection.summaryLikeCount }} voted this summary</p> -->
                  <p class="blink-sum ml-2" *ngIf="collection.summaryLike">
                    you and {{ collection.summaryLikeCount }} others voted this
                    summary
                  </p>

                  <!-- /* comments section */ -->
                  <div
                    class="comments-section ml-md-5 pl-2 pb-4 mt-4 pt-3"
                    *ngIf="collection.showCollectionComment"
                  >
                    <h5 class="total_comments">{{ totalComments }} Comments</h5>
                    <div class="comment-header">
                      <div class="d-flex">
                        <div class="comment-img">
                          <img
                            *ngIf="
                              collection.thumbNail != '' &&
                              collection.customThumbNail == null
                            "
                            src="{{ collection.thumbNail }}"
                            class="c-img-1"
                            loading="lazy"
                            alt="{{ collection.summaryName }}"
                          />
                          <img
                            *ngIf="
                              collection.customThumbNail != '' &&
                              collection.thumbNail == null
                            "
                            src="{{ collection.customThumbNail }}"
                            class="c-img-1"
                            loading="lazy"
                            alt="customThumbnNail"
                          />
                        </div>
                        <div class="comment-input-field ml-md-3">
                          <form [formGroup]="commentForm">
                            <input
                              [readOnly]="readonly"
                              (keyup)="nameValidations($event, 'title')"
                              class="comment-form-input"
                              [class.inputActive]="inputActive == true"
                              formControlName="title"
                              (focus)="toggleButtons()"
                              (click)="commentEvent()"
                            />
                            <div
                              *ngIf="commentFormError.title.errors?.maxlength"
                              class="invalid-feedback m-t d-block"
                            >
                              Only 1000 characters are allowed
                            </div>
                            <div
                              class="d-flex mt-3 justify-content-md-end comment-add_form"
                              *ngIf="showButtons"
                            >
                              <button
                                class="cancel-comment mr-md-3 cursor"
                                (click)="cancelComment()"
                              >
                                Cancel
                              </button>
                              <button
                                class="comment-form-button cursor"
                                [disabled]="commentForm.invalid"
                                type="submit"
                                (click)="onSubmitComment(collection)"
                              >
                                Comment
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <app-loader
                      [commentsLoader]="commentsLoaderPostion"
                      *ngIf="showCommentLoader"
                    ></app-loader>
                    <div
                      class="comments-user-added mt-4"
                      *ngFor="let comments of commentUser"
                    >
                      <div class="d-flex">
                        <div class="comment-user-img">
                          <img
                            *ngIf="comments?.reader[0]?.profile == null"
                            src="../../../assets/images/pr-img.jpeg"
                          />
                          <img
                            *ngIf="comments?.reader[0]?.profile != null"
                            src="{{ comments?.reader[0]?.profile }}"
                          />
                        </div>
                        <div class="user-comments-data ml-md-3">
                          <div class="d-flex">
                            <h4>@{{ comments?.reader[0]?.name }}</h4>
                            <p class="ml-2">
                              {{ comments.createdAt | datepipe }}
                            </p>
                          </div>
                          <h6>{{ comments.comment }}</h6>
                          <!-- EDIT-COMMENT-FORM-SECTION -->
                          <div
                            class="comment-input-field ml-md-3"
                            *ngIf="comments.isEdit"
                          >
                            <form [formGroup]="editCommentForm">
                              <input
                                [readOnly]="readonly"
                                (keyup)="nameValidations($event, 'edit')"
                                class="comment-form-input"
                                [class.inputActive]="editActive == true"
                                formControlName="edit"
                                (focus)="toggleEditButtons()"
                                (click)="commentEvent()"
                              />
                              <div
                                class="d-flex mt-3 justify-content-end"
                                *ngIf="showEditButtons"
                              >
                                <button
                                  class="cancel-comment mr-md-3 cursor"
                                  (click)="cancelEditComment(comments)"
                                >
                                  Cancel
                                </button>
                                <button
                                  class="comment-form-button cursor"
                                  [disabled]="editCommentForm.invalid"
                                  type="submit"
                                  (click)="saveEditForm(comments)"
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          </div>
                          <!-- EDIT-COMMENT-FORM-SECTION -->
                          <div class="d-flex">
                            <a
                              class="reply mr-md-3 cursor mr-2"
                              (click)="replyShowInput(comments)"
                            >
                              <h3>Reply</h3>
                            </a>
                            <a
                              class="reply cursor mr-md-3 mr-2"
                              *ngIf="comments?.reader[0]?._id == userTypeId"
                              (click)="editMainComment(comments)"
                            >
                              <h3>Edit</h3>
                            </a>
                            <a
                              class="reply cursor"
                              (click)="deletComment(comments)"
                              *ngIf="comments?.reader[0]?._id == userTypeId"
                            >
                              <h3>Delete</h3>
                            </a>
                          </div>
                        </div>
                        <div class="ml-md-auto mat-menu-collection">
                          <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu"
                          >
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu
                            #menu="matMenu"
                            class="mat-menu"
                            yPosition="above"
                          >
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="spamComment(comments, collection)"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/red-flag.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Mark as Spam</span>
                            </button>
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="spamOffensive(comments, collection)"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/sad.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Mark as Offensive</span>
                            </button>
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              *ngIf="collection?.reader[0]?._id == userTypeId"
                              (click)="
                                deleteCommentPublisherandPowerUser(comments)
                              "
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/de.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Delete Comment</span>
                            </button>
                          </mat-menu>
                        </div>
                        <!-- <div class="comment-spam-img d-flex ml-md-auto"><a (click)="spamComment(comments)"><img  src="../../../assets/images/spam (1).png" class="mr-3"></a><a (click)="spamOffensive(comments)"><img  src="../../../assets/images/report (1).png"></a></div> -->
                      </div>
                      <div
                        class="comment-header ml-md-5 pl-md-3 mt-md-3"
                        *ngIf="comments.showReplyForm"
                      >
                        <div class="d-flex">
                          <div class="comment-img">
                            <img
                              src="../../../assets/images/user-1.png"
                              width="50"
                            />
                          </div>
                          <div class="comment-input-field ml-md-3">
                            <form [formGroup]="replyCommentForm">
                              <input
                                class="comment-form-input"
                                (keyup)="nameValidations($event, 'reply')"
                                [class.inputActive]="inputActive == true"
                                formControlName="reply"
                                (focus)="toggleButtonsReply()"
                                (input)="commentEventReply($event)"
                              />
                              <div
                                *ngIf="
                                  replyCommentFormError.reply.errors?.maxlength
                                "
                                class="invalid-feedback m-t d-block"
                              >
                                Only 1000 characters are allowed
                              </div>
                              <div
                                class="d-flex mt-3 justify-content-end"
                                *ngIf="showButtonsReply"
                              >
                                <button
                                  class="cancel-comment mr-md-3 cursor"
                                  (click)="cancelCommentReply(comments)"
                                >
                                  Cancel
                                </button>
                                <button
                                  class="cursor comment-form-button"
                                  [disabled]="replyCommentForm.invalid"
                                  type="submit"
                                  (click)="onReplyComment()"
                                >
                                  Reply
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <a
                        class="ml-5 pl-md-3 pl-2 replycount"
                        *ngIf="comments.commentReply.length != 0"
                        (click)="showReplyBtn(comments)"
                        >{{ comments.commentReply.length }} reply</a
                      >
                      <div *ngIf="comments.showReplys">
                        <div
                          class="comments-user-added mt-md-4 mt-3 ml-md-5"
                          *ngFor="let reply of comments.commentReply"
                        >
                          <div class="d-flex">
                            <div class="comment-user-img">
                              <img
                                *ngIf="reply.profile == null"
                                src="../../../assets/images/pr-img.jpeg"
                              />
                              <img
                                *ngIf="reply.profile != null"
                                src="{{ reply.profile }}"
                              />
                            </div>
                            <div class="user-comments-data ml-md-3">
                              <div class="d-flex">
                                <h4>@{{ reply.name }}</h4>
                                <p class="ml-2">
                                  {{ reply.createdAt | datepipe }}
                                </p>
                              </div>
                              <h6>{{ reply.comment }}</h6>
                              <!-- EDIT-COMMENT-FORM-SECTION -->
                              <div
                                class="comment-input-field ml-md-3"
                                *ngIf="reply.isEdit"
                              >
                                <form [formGroup]="replyEditCommentForm">
                                  <input
                                    [readOnly]="readonly"
                                    (keyup)="
                                      nameValidations($event, 'replyEdit')
                                    "
                                    class="comment-form-input"
                                    [class.inputActive]="editActive == true"
                                    formControlName="replyEdit"
                                    (focus)="toggleReplyEditButtons()"
                                    (click)="commentEvent()"
                                  />
                                  <div
                                    class="d-flex mt-3 justify-content-end"
                                    *ngIf="showReplyEditButtons"
                                  >
                                    <button
                                      class="cancel-comment mr-md-3 cursor"
                                      (click)="cancelEditComment(reply)"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="comment-form-button cursor"
                                      [disabled]="replyEditCommentForm.invalid"
                                      type="submit"
                                      (click)="saveReplyEditForm(comments)"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <!-- EDIT-COMMENT-FORM-SECTION -->
                              <div class="d-flex">
                                <a
                                  class="reply cursor mr-3"
                                  *ngIf="reply.readerId == userTypeId"
                                  (click)="editReplyComment(reply)"
                                >
                                  <h3>Edit</h3>
                                </a>
                                <a
                                  class="reply cursor"
                                  (click)="
                                    deletReplyComment(comments, reply._id)
                                  "
                                  *ngIf="reply.readerId == userTypeId"
                                >
                                  <h3>Delete</h3>
                                </a>
                              </div>
                            </div>
                            <div class="ml-md-auto mat-menu-collection">
                              <button
                                mat-icon-button
                                [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu"
                              >
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              <mat-menu
                                #menu="matMenu"
                                class="mat-menu"
                                yPosition="above"
                              >
                                <button
                                  mat-menu-item
                                  class="mat-item-span"
                                  (click)="
                                    spamReplyComment(
                                      comments,
                                      reply._id,
                                      collection
                                    )
                                  "
                                >
                                  <mat-icon>
                                    <img
                                      src="../../../assets/images/red-flag.png"
                                      class="mr-3 spam-img"
                                    />
                                  </mat-icon>
                                  <span>Mark as Spam</span>
                                </button>
                                <button
                                  mat-menu-item
                                  class="mat-item-span"
                                  (click)="
                                    spamReplyOffensive(
                                      comments,
                                      reply._id,
                                      collection
                                    )
                                  "
                                >
                                  <mat-icon>
                                    <img
                                      src="../../../assets/images/sad.png"
                                      class="mr-3 spam-img"
                                    />
                                  </mat-icon>
                                  <span>Mark as Offensive</span>
                                </button>
                                <button
                                  mat-menu-item
                                  class="mat-item-span"
                                  (click)="
                                    deleteReplyCommentPublisherandPowerUser(
                                      comments,
                                      reply._id
                                    )
                                  "
                                  *ngIf="
                                    collection?.reader[0]?._id == userTypeId
                                  "
                                >
                                  <mat-icon>
                                    <img
                                      src="../../../assets/images/de.png"
                                      class="mr-3 spam-img"
                                    />
                                  </mat-icon>
                                  <span>Delete Comment</span>
                                </button>
                              </mat-menu>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /* comments section */ -->

                  <!-- NEW-COMMENT-SECTION -->

                  <div class="new_comment_section">
                    <div class="d-flex">
                      <div class="comment_content">
                        <p>
                          This summary has been generated by AI from the
                          <a
                            class="text-decoration-underline"
                            target="_blank"
                            [href]="collection.video"
                            >Youtube video</a
                          >
                        </p>
                        <h4>
                          Comment this Summary <a>link </a> in the Youtube video
                          and help others in youtube community read this summary
                        </h4>
                      </div>
                      <div
                        class="Comment_img ml-auto cursor"
                        (click)="writeComment(collection)"
                      >
                        <img src="../../assets/images/comment.png" />
                        <p>Write Comment</p>
                      </div>
                    </div>
                  </div>
                  <!-- NEW-COMMENT-SECTION -->
                </div>
              </div>
              <div infinite-scroll (scrolled)="onScrollDown($event)">
                <!-- <span class="loader_data" ></span> -->
                <div *ngFor="let collection of collectionList; let i = index">
                  <div
                    class="summary card mb-2"
                    id="fullSummary"
                    (click)="collectionsClick(collection._id)"
                  >
                    <div class="card-body">
                      <div class="d-flex summary-p">
                        <div class="summary-header ml-md-4 channel-details">
                          <div class="summary-start">
                            <div class="d-flex">
                              <!-- <div class="moblie-tweet"><div *ngIf="!collection.bookmarkSummary" class="comment ml-4" (click)="saveCollection(collection,true,collection._id,collection.summaryName)"><img src="../../../assets/images/h.png" class="img-b"></div><div *ngIf="collection.bookmarkSummary" class="comment ml-5"><img src="../../../assets/images/he-1.png" class="img-b"></div></div> -->
                              <a
                                href="/c/{{
                                  collection?.summaryName?.split(' ')?.join('-')
                                }}/id-{{ collection._id }}"
                                (click)="collectionsClick(collection._id)"
                              >
                                <h3>{{ collection.summaryName }}</h3>
                              </a>
                              <!-- <div class="ml-md-auto mat-menu-collection">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon>
                                    <img src="../../assets/images/dots.png" class="report">
                              
                                  </mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="mat-menu" xPosition="after">
                                  <button mat-menu-item class="mat-item-span" (click)="report('Inaccurate',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/red-flag.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Inaccurate</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Spam',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/red-flag.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Spam</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Low quality',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/sad.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Low quality</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Offensive ',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/sad.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Offensive</span>
                                  </button>
                                  <button mat-menu-item class="mat-item-span" (click)="report('Translation error',collection)">
                                    <mat-icon>
                                      <img src="../../../assets/images/sad.png" class="mr-3 spam-img">
                                    </mat-icon>
                                    <span>Translation error</span>
                                  </button>
                                </mat-menu>
                              </div> -->
                            </div>
                            <img
                              *ngIf="
                                collection.thumbNail != '' &&
                                collection.customThumbNail == null
                              "
                              src="{{ collection.thumbNail }}"
                              class="img"
                              loading="lazy"
                              alt="{{ collection.summaryName }}"
                            />
                            <img
                              *ngIf="
                                collection.customThumbNail != '' &&
                                collection.thumbNail == null
                              "
                              src="{{ collection.customThumbNail }}"
                              class="img"
                              loading="lazy"
                              alt="customThumbnNail"
                            />
                            <p class="views">
                              {{ collection.viewCount | records }} video views
                              on Youtube
                            </p>
                            <!-- <h6>Video source <a target="_blank" href="{{collection.video}}">Youtube</a></h6> -->
                            <div class="channel-avatar-section mr-5">
                              <div class="d-flex">
                                <div class="channel-details">
                                  <a
                                    (click)="
                                      navigateChannel(collection.publisherName)
                                    "
                                  >
                                    <h4>
                                      Published by
                                      <span class="text-decoration-underline">{{
                                        collection?.publisherName
                                      }}</span>
                                    </h4>
                                  </a>
                                </div>
                                <div class="channel-details ml-md-3">
                                  <a
                                    class="subscribed_1"
                                    *ngIf="!collection.followPublisher"
                                    (click)="subscribeChannel(collection, true)"
                                    >Follow</a
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              [class.active]="blogActive"
                              class="summary-paragrph summary-text"
                              innerHTML="{{ collection.Summary }}"
                            ></div>
                          </div>
                          <div class="mt-1 read-s">
                            <a
                              href="/c/{{
                                collection?.summaryName?.split(' ')?.join('-')
                              }}/id-{{ collection._id }}"
                              class="anchor-tag"
                              >Show more</a
                            >
                          </div>
                        </div>
                        <!-- <div class="report_problem"><img src="../../assets/images/warning (1).png"></div> -->
                      </div>
                      <!-- <div class="channel-card pl-md-5">
                        <div class="d-md-flex">
                          <div>
                            <div>
                              <div class='category-follow mt-2 mr-3' *ngFor="let categories of collection.topics | fliterCategorty: {name : activeState}">
                                <div class="d-flex ">
                                  <div *ngIf="!categories.topicsFollow" class="catergory-details">
                                    <div class="d-flex">
                                      <a (click)="catergoryNavigate(categories.name)">
                                        <h6 class="mb-md-2">Want to read more summaries on <span>{{categories.name}}</span>? join {{categories.name}} community </h6>
                                      </a>
                                      <a class="not-login_catergory" (click)="followBtn(categories,true,collection)">join</a>
                                    </div>
                                  </div>
                                  <div *ngIf="categories.topicsFollow" class="catergory-details-1">
                                    <div class="d-flex">
                                      <a (click)="catergoryNavigate(categories.name)">
                                        <h6 class="mb-md-2">Great, you are following <span>{{categories.name}} </span> topic </h6>
                                      </a>
                                      <a class="catergoryAnchorTag" (click)="unFollowCategories(categories,true,collection)">
                                        <span class="glyphicon glyphicon-">Following</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="share-section  ml-auto">
                            <div class="d-flex justify-content-md-around media-screen-flex">
                              <div class="comment" (click)="share(collection)">
                                <img src="../../../assets/images/send.png">
                                <p>share</p>
                              </div>
                              <div class="thumps-up-image pointer">
                                <button class="upVote" *ngIf="!collection.summaryLike" (click)="
                            likeCollections(collection,true,collection._id)">
                                  <img src="../../../assets/images/like012.png">
                                  <p id="upvote "> Like </p>
                                </button>
                                <button class="upVote" *ngIf="collection.summaryLike">
                                  <img src="../../../assets/images/like012.png">
                                  <p id="upvote" class=" Blinked"> liked </p>
                                </button>
                              </div>
                              <div class="">
                                <div *ngIf="!collection.bookmarkSummary" class="comment" (click)="saveCollection(collection,true,collection._id,collection.summaryName)">
                                  <img src="../../../assets/images/bookmark.png">
                                  <p class="bookmark">bookmark</p>
                                </div>
                                <div *ngIf="collection.bookmarkSummary" class="comment">
                                  <img src="../../../assets/images/bookmark.png">
                                  <p>bookmarked</p>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div class="d-flex mx-3 my-2">
                        <div class="d-md-flex">
                          <div class="d-flex">
                            <div>
                              <img
                                src="../../../assets/images/vote.png"
                                width="50px"
                                *ngIf="!collection.summaryLike"
                                (click)="
                                  likeCollections(
                                    collection,
                                    true,
                                    collection._id
                                  )
                                "
                              />
                              <img
                                src="../../../assets/images/vote (1).png"
                                width="50px"
                                *ngIf="collection.summaryLike"
                              />
                            </div>

                            <div class="image-container cursor">
                              <img
                                src="../../assets/images/information.png"
                                class="img-book"
                              />
                              <p class="image-text">
                                If you like this summary you can vote for it
                              </p>
                            </div>
                          </div>

                          <div
                            *ngFor="
                              let categories of collection.topics
                                | fliterCategorty : { name: activeState }
                            "
                          >
                            <div
                              class="category-follow mt-md-4 mr-md-2 ml-md-2"
                              *ngIf="!categories.topicsFollow"
                            >
                              <div class="d-flex">
                                <div class="catergory-details">
                                  <div class="d-flex">
                                    <a
                                      (click)="
                                        catergoryNavigate(categories.name)
                                      "
                                    >
                                      <h6 class="mb-md-2">
                                        Want to read more summaries on
                                        <span>{{ categories.name }}</span
                                        >? join {{ categories.name }} community
                                      </h6>
                                    </a>
                                    <a
                                      class="not-login_catergory"
                                      (click)="
                                        followBtn(categories, true, collection)
                                      "
                                      >join</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="ml-auto">
                          <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu1"
                            aria-label="Example icon-button with a menu"
                          >
                            <mat-icon>
                              <img
                                src="../../assets/images/dots.png"
                                class="report"
                                width="35px"
                              />
                              <!-- <p class="report-text">report a problem</p> -->
                            </mat-icon>
                          </button>
                          <mat-menu
                            #menu1="matMenu"
                            class="mat-menu"
                            xPosition="after"
                          >
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              *ngIf="!collection.bookmarkSummary"
                              (click)="
                                saveCollection(
                                  collection,
                                  true,
                                  collection._id,
                                  collection.summaryName
                                )
                              "
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon feather-bookmark.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Bookmark</span>
                            </button>

                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="link()"
                              cdkCopyToClipboard="{{ url }}/c/{{
                                collection?.summaryName?.split(' ')?.join('-')
                              }}/id-{{ collection._id }}"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon feather-link-2.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Copy link</span>
                            </button>
                            <button mat-menu-item class="mat-item-span">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/not-equal.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Not interested in this</span>
                            </button>
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="
                                dislikeSummary(collection, true, collection._id)
                              "
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/not-equal.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Downvote</span>
                            </button>
                            <ng-container
                              *ngFor="
                                let categories of collection.topics
                                  | fliterCategorty : { name: activeState }
                              "
                            >
                              <button
                                mat-menu-item
                                class="mat-item-span"
                                (click)="
                                  unFollowCategories(
                                    categories,
                                    true,
                                    collection
                                  )
                                "
                                *ngIf="categories.topicsFollow"
                              >
                                <mat-icon>
                                  <img
                                    src="../../../assets/images/Icon feather-delete.svg"
                                    class="mr-3 spam-img"
                                  />
                                </mat-icon>
                                <span>Unfollow {{ categories.name }}</span>
                              </button>
                            </ng-container>

                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="UnfollowBtn(collection, true)"
                              *ngIf="collection.followPublisher"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/sad.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span
                                >Unfollow {{ collection.publisherName }}</span
                              >
                            </button>
                            <button mat-menu-item class="mat-item-span" (click)="share(collection)">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon awesome-share-alt.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Share with a friend </span>
                            </button>
                            <button mat-menu-item class="mat-item-span">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon awesome-share-alt.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Dowload summary words</span>
                            </button>
                            <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="report(collection)"
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon material-report-problem.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Report a problem</span>
                            </button>
                            <button mat-menu-item class="mat-item-span">
                              <mat-icon>
                                <img
                                  src="../../../assets/images/Icon zocial-email.svg"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Contact us</span>
                            </button>
                          </mat-menu>
                        </div>
                      </div>

                      <p *ngIf="!collection.summaryLike" class="like-web ml-2">
                        Vote this summary
                      </p>

                      <!-- <p *ngIf="collection.summaryLikeCount != 0 && collection.summaryLikeCount == true">{{ collection.summaryLikeCount }} voted this summary</p> -->
                      <p class="blink-sum ml-2" *ngIf="collection.summaryLike">
                        you and {{ collection.summaryLikeCount }} others voted
                        this summary
                      </p>

                      <!-- /* comments section */ -->
                      <div
                        class="comments-section ml-md-5 pl-2 pb-4 mt-4 pt-3"
                        *ngIf="collection.showCollectionComment"
                      >
                        <h5 class="total_comments">
                          {{ totalComments }} Comments
                        </h5>
                        <div class="comment-header">
                          <div class="d-flex">
                            <div class="comment-img">
                              <img
                                *ngIf="
                                  collection.thumbNail != '' &&
                                  collection.customThumbNail == null
                                "
                                src="{{ collection.thumbNail }}"
                                class="c-img-1"
                                loading="lazy"
                                alt="{{ collection.summaryName }}"
                              />
                              <img
                                *ngIf="
                                  collection.customThumbNail != '' &&
                                  collection.thumbNail == null
                                "
                                src="{{ collection.customThumbNail }}"
                                class="c-img-1"
                                loading="lazy"
                                alt="customThumbnNail"
                              />
                            </div>
                            <div class="comment-input-field ml-md-3">
                              <form [formGroup]="commentForm">
                                <input
                                  [readOnly]="readonly"
                                  (keyup)="nameValidations($event, 'title')"
                                  class="comment-form-input"
                                  [class.inputActive]="inputActive == true"
                                  formControlName="title"
                                  (focus)="toggleButtons()"
                                  (click)="commentEvent()"
                                />
                                <div
                                  *ngIf="
                                    commentFormError.title.errors?.maxlength
                                  "
                                  class="invalid-feedback m-t d-block"
                                >
                                  Only 1000 characters are allowed
                                </div>
                                <div
                                  class="d-flex mt-3 justify-content-md-end comment-add_form"
                                  *ngIf="showButtons"
                                >
                                  <button
                                    class="cancel-comment mr-md-3 cursor"
                                    (click)="cancelComment()"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    class="comment-form-button cursor"
                                    [disabled]="commentForm.invalid"
                                    type="submit"
                                    (click)="onSubmitComment(collection)"
                                  >
                                    Comment
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <app-loader
                          [commentsLoader]="commentsLoaderPostion"
                          *ngIf="showCommentLoader"
                        ></app-loader>
                        <div
                          class="comments-user-added mt-4"
                          *ngFor="let comments of commentUser"
                        >
                          <div class="d-flex">
                            <div class="comment-user-img">
                              <img
                                *ngIf="comments?.reader[0]?.profile == null"
                                src="../../../assets/images/pr-img.jpeg"
                              />
                              <img
                                *ngIf="comments?.reader[0]?.profile != null"
                                src="{{ comments?.reader[0]?.profile }}"
                              />
                            </div>
                            <div class="user-comments-data ml-md-3">
                              <div class="d-flex">
                                <h4>@{{ comments?.reader[0]?.name }}</h4>
                                <p class="ml-2">
                                  {{ comments.createdAt | datepipe }}
                                </p>
                              </div>
                              <h6>{{ comments.comment }}</h6>
                              <!-- EDIT-COMMENT-FORM-SECTION -->
                              <div
                                class="comment-input-field ml-md-3"
                                *ngIf="comments.isEdit"
                              >
                                <form [formGroup]="editCommentForm">
                                  <input
                                    [readOnly]="readonly"
                                    (keyup)="nameValidations($event, 'edit')"
                                    class="comment-form-input"
                                    [class.inputActive]="editActive == true"
                                    formControlName="edit"
                                    (focus)="toggleEditButtons()"
                                    (click)="commentEvent()"
                                  />
                                  <div
                                    class="d-flex mt-3 justify-content-end"
                                    *ngIf="showEditButtons"
                                  >
                                    <button
                                      class="cancel-comment mr-md-3 cursor"
                                      (click)="cancelEditComment(comments)"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="comment-form-button cursor"
                                      [disabled]="editCommentForm.invalid"
                                      type="submit"
                                      (click)="saveEditForm(comments)"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                              </div>
                              <!-- EDIT-COMMENT-FORM-SECTION -->
                              <div class="d-flex">
                                <a
                                  class="reply mr-md-3 cursor mr-2"
                                  (click)="replyShowInput(comments)"
                                >
                                  <h3>Reply</h3>
                                </a>
                                <a
                                  class="reply cursor mr-md-3 mr-2"
                                  *ngIf="comments?.reader[0]?._id == userTypeId"
                                  (click)="editMainComment(comments)"
                                >
                                  <h3>Edit</h3>
                                </a>
                                <a
                                  class="reply cursor"
                                  (click)="deletComment(comments)"
                                  *ngIf="comments?.reader[0]?._id == userTypeId"
                                >
                                  <h3>Delete</h3>
                                </a>
                              </div>
                            </div>
                            <div class="ml-md-auto mat-menu-collection">
                              <button
                                mat-icon-button
                                [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu"
                              >
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              <mat-menu
                                #menu="matMenu"
                                class="mat-menu"
                                yPosition="above"
                              >
                                <button
                                  mat-menu-item
                                  class="mat-item-span"
                                  (click)="spamComment(comments, collection)"
                                >
                                  <mat-icon>
                                    <img
                                      src="../../../assets/images/red-flag.png"
                                      class="mr-3 spam-img"
                                    />
                                  </mat-icon>
                                  <span>Mark as Spam</span>
                                </button>
                                <button
                                  mat-menu-item
                                  class="mat-item-span"
                                  (click)="spamOffensive(comments, collection)"
                                >
                                  <mat-icon>
                                    <img
                                      src="../../../assets/images/sad.png"
                                      class="mr-3 spam-img"
                                    />
                                  </mat-icon>
                                  <span>Mark as Offensive</span>
                                </button>
                                <button
                                  mat-menu-item
                                  class="mat-item-span"
                                  *ngIf="
                                    collection?.reader[0]?._id == userTypeId
                                  "
                                  (click)="
                                    deleteCommentPublisherandPowerUser(comments)
                                  "
                                >
                                  <mat-icon>
                                    <img
                                      src="../../../assets/images/de.png"
                                      class="mr-3 spam-img"
                                    />
                                  </mat-icon>
                                  <span>Delete Comment</span>
                                </button>
                              </mat-menu>
                            </div>
                            <!-- <div class="comment-spam-img d-flex ml-md-auto"><a (click)="spamComment(comments)"><img  src="../../../assets/images/spam (1).png" class="mr-3"></a><a (click)="spamOffensive(comments)"><img  src="../../../assets/images/report (1).png"></a></div> -->
                          </div>
                          <div
                            class="comment-header ml-md-5 pl-md-3 mt-md-3"
                            *ngIf="comments.showReplyForm"
                          >
                            <div class="d-flex">
                              <div class="comment-img">
                                <img
                                  src="../../../assets/images/user-1.png"
                                  width="50"
                                />
                              </div>
                              <div class="comment-input-field ml-md-3">
                                <form [formGroup]="replyCommentForm">
                                  <input
                                    class="comment-form-input"
                                    (keyup)="nameValidations($event, 'reply')"
                                    [class.inputActive]="inputActive == true"
                                    formControlName="reply"
                                    (focus)="toggleButtonsReply()"
                                    (input)="commentEventReply($event)"
                                  />
                                  <div
                                    *ngIf="
                                      replyCommentFormError.reply.errors
                                        ?.maxlength
                                    "
                                    class="invalid-feedback m-t d-block"
                                  >
                                    Only 1000 characters are allowed
                                  </div>
                                  <div
                                    class="d-flex mt-3 justify-content-end"
                                    *ngIf="showButtonsReply"
                                  >
                                    <button
                                      class="cancel-comment mr-md-3 cursor"
                                      (click)="cancelCommentReply(comments)"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="cursor comment-form-button"
                                      [disabled]="replyCommentForm.invalid"
                                      type="submit"
                                      (click)="onReplyComment()"
                                    >
                                      Reply
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <a
                            class="ml-5 pl-md-3 pl-2 replycount"
                            *ngIf="comments.commentReply.length != 0"
                            (click)="showReplyBtn(comments)"
                            >{{ comments.commentReply.length }} reply</a
                          >
                          <div *ngIf="comments.showReplys">
                            <div
                              class="comments-user-added mt-md-4 mt-3 ml-md-5"
                              *ngFor="let reply of comments.commentReply"
                            >
                              <div class="d-flex">
                                <div class="comment-user-img">
                                  <img
                                    *ngIf="reply.profile == null"
                                    src="../../../assets/images/pr-img.jpeg"
                                  />
                                  <img
                                    *ngIf="reply.profile != null"
                                    src="{{ reply.profile }}"
                                  />
                                </div>
                                <div class="user-comments-data ml-md-3">
                                  <div class="d-flex">
                                    <h4>@{{ reply.name }}</h4>
                                    <p class="ml-2">
                                      {{ reply.createdAt | datepipe }}
                                    </p>
                                  </div>
                                  <h6>{{ reply.comment }}</h6>
                                  <!-- EDIT-COMMENT-FORM-SECTION -->
                                  <div
                                    class="comment-input-field ml-md-3"
                                    *ngIf="reply.isEdit"
                                  >
                                    <form [formGroup]="replyEditCommentForm">
                                      <input
                                        [readOnly]="readonly"
                                        (keyup)="
                                          nameValidations($event, 'replyEdit')
                                        "
                                        class="comment-form-input"
                                        [class.inputActive]="editActive == true"
                                        formControlName="replyEdit"
                                        (focus)="toggleReplyEditButtons()"
                                        (click)="commentEvent()"
                                      />
                                      <div
                                        class="d-flex mt-3 justify-content-end"
                                        *ngIf="showReplyEditButtons"
                                      >
                                        <button
                                          class="cancel-comment mr-md-3 cursor"
                                          (click)="cancelEditComment(reply)"
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          class="comment-form-button cursor"
                                          [disabled]="
                                            replyEditCommentForm.invalid
                                          "
                                          type="submit"
                                          (click)="saveReplyEditForm(comments)"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                  <!-- EDIT-COMMENT-FORM-SECTION -->
                                  <div class="d-flex">
                                    <a
                                      class="reply cursor mr-3"
                                      *ngIf="reply.readerId == userTypeId"
                                      (click)="editReplyComment(reply)"
                                    >
                                      <h3>Edit</h3>
                                    </a>
                                    <a
                                      class="reply cursor"
                                      (click)="
                                        deletReplyComment(comments, reply._id)
                                      "
                                      *ngIf="reply.readerId == userTypeId"
                                    >
                                      <h3>Delete</h3>
                                    </a>
                                  </div>
                                </div>
                                <div class="ml-md-auto mat-menu-collection">
                                  <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu"
                                  >
                                    <mat-icon>more_vert</mat-icon>
                                  </button>
                                  <mat-menu
                                    #menu="matMenu"
                                    class="mat-menu"
                                    yPosition="above"
                                  >
                                    <button
                                      mat-menu-item
                                      class="mat-item-span"
                                      (click)="
                                        spamReplyComment(
                                          comments,
                                          reply._id,
                                          collection
                                        )
                                      "
                                    >
                                      <mat-icon>
                                        <img
                                          src="../../../assets/images/red-flag.png"
                                          class="mr-3 spam-img"
                                        />
                                      </mat-icon>
                                      <span>Mark as Spam</span>
                                    </button>
                                    <button
                                      mat-menu-item
                                      class="mat-item-span"
                                      (click)="
                                        spamReplyOffensive(
                                          comments,
                                          reply._id,
                                          collection
                                        )
                                      "
                                    >
                                      <mat-icon>
                                        <img
                                          src="../../../assets/images/sad.png"
                                          class="mr-3 spam-img"
                                        />
                                      </mat-icon>
                                      <span>Mark as Offensive</span>
                                    </button>
                                    <button
                                      mat-menu-item
                                      class="mat-item-span"
                                      (click)="
                                        deleteReplyCommentPublisherandPowerUser(
                                          comments,
                                          reply._id
                                        )
                                      "
                                      *ngIf="
                                        collection?.reader[0]?._id == userTypeId
                                      "
                                    >
                                      <mat-icon>
                                        <img
                                          src="../../../assets/images/de.png"
                                          class="mr-3 spam-img"
                                        />
                                      </mat-icon>
                                      <span>Delete Comment</span>
                                    </button>
                                  </mat-menu>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /* comments section */ -->

                      <!-- NEW-COMMENT-SECTION -->
                                 <div *ngFor="let item of toggles">

                                  <div class="new_comment_section" *ngIf="item.CommentToggle">
                        <div class="d-flex">
                          <div class="comment_content">
                            <p>
                              This summary has been generated by AI from the
                              <a
                                class="text-decoration-underline"
                                target="_blank"
                                [href]="collection.video"
                                >Youtube video</a
                              >
                            </p>
                            <h4>
                              Comment this Summary <a>link </a> in the Youtube
                              video and help others in youtube community read
                              this summary
                            </h4>
                          </div>
                          <div
                            class="Comment_img ml-auto cursor"
                            (click)="writeComment(collection)"
                          >
                            <img src="../../assets/images/comment.png" />
                            <p>Write Comment</p>
                          </div>
                        </div>
                        
                      </div>
                      <div class="reddit" *ngIf="item.RedditToggle">
                          <div class="d-flex" *ngFor="
                              let categories of collection.topics
                                | fliterCategorty : { name: activeState }
                            ">
                            <div class="comment_content" >
                              <h4>
                                Submit this summary link in
                                <a class="text-decoration-underline"
                                  >r/{{categories.redditMessage}}</a
                                >
                                sub-reddit. Earn more
                                <a class="text-decoration-underline">Karma </a
                                >on Reddit
                              </h4>
                            </div>
                            <div class="Comment_img ml-auto cursor">
                              <img
                                src="../../assets/images/Icon awesome-reddit.svg"
                              />
                              <p>{{categories.ctaText}}</p>
                            </div>
                          </div>
                        </div>
                                </div>
                      
                      <!-- NEW-COMMENT-SECTION -->
                    </div>
                  </div>
                  <div *ngIf="recommendedTopics != ''">
                    <div
                      class="recommend_topics my-3"
                      *ngIf="(i + 1) % 10 === 5"
                    >
                      <div class="card">
                        <div class="card-body">
                          <h6 class="ml-md-4">
                            Recommended topics for you follow
                          </h6>
                          <div class="publisher_topics_cards ml-md-4">
                            <div
                              class="d-md-flex justify-content-md-around recommenede_data"
                            >
                              <div
                                class="card"
                                *ngFor="
                                  let item of recommendedTopics?.slice(0, 6);
                                  let i = index
                                "
                              >
                                <ng-container *ngIf="item != 'ALL'">
                                  <div class="card-body">
                                    <img
                                      src="../../assets/images/close-m.svg"
                                      id="close_p"
                                      (click)="closeTopics(i)"
                                    />
                                    <div class="header_body">
                                      <img
                                        *ngIf="item.topicImage != null"
                                        src="{{ item.topicImage }}"
                                      />
                                      <img
                                        *ngIf="item.topicImage == null"
                                        src="../../assets/images/user-1.png"
                                      />
                                      <h4>{{ item.name?.slice(0, 14) }}</h4>
                                      <button
                                        *ngIf="!item.topicFollowed"
                                        class="cursor follow_publisher_card"
                                        (click)="followTopic(item)"
                                      >
                                        follow
                                      </button>
                                      <button
                                        *ngIf="item.topicFollowed"
                                        class="cursor follow_publisher_card"
                                      >
                                        Following
                                      </button>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="recommendedPublishers != ''">
                    <div
                      class="recommend_topics my-3"
                      *ngIf="(i + 1) % 10 === 0"
                    >
                      <div class="card">
                        <div class="card-body">
                          <h6 class="ml-md-4">
                            Recommended publisher for you follow
                          </h6>
                          <div class="publisher_topics_cards ml-md-4">
                            <div
                              class="d-md-flex justify-content-md-around recommenede_data"
                            >
                              <div
                                class="card"
                                *ngFor="
                                  let item of recommendedPublishers?.slice(
                                    0,
                                    6
                                  );
                                  let i = index
                                "
                              >
                                <div class="card-body">
                                  <img
                                    src="../../assets/images/close-m.svg"
                                    id="close_p"
                                    (click)="closePubliser(i)"
                                  />
                                  <div class="header_body">
                                    <img
                                      *ngIf="item.publisherImage != null"
                                      src="{{ item.publisherImage }}"
                                    />
                                    <img
                                      *ngIf="item.publisherImage == null"
                                      src="../../assets/images/user-1.png"
                                    />
                                    <h4>{{ item?.name.slice(0, 14) }}</h4>

                                    <button
                                      *ngIf="!item.followChannel"
                                      class="cursor follow_publisher_card"
                                      (click)="followPublisher(item)"
                                    >
                                      Follow
                                    </button>
                                    <button
                                      *ngIf="item.followChannel"
                                      class="cursor follow_publisher_card"
                                    >
                                      Following
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-5">
              <div class="realted-collection">
                <div
                  class="reaalted_publisher"
                  *ngIf="recommendedPublishers != ''"
                >
                  <div class="card">
                    <div class="card-body">
                      <h5>Trending Publishers in {{ activeState }}</h5>
                      <div
                        class="d-flex mt-md-2"
                        *ngFor="
                          let item of recommendedPublishers?.slice(0, 7);
                          let i = index
                        "
                      >
                        <!-- <div class="" *ngIf="item"></div> -->
                        <div class="img-publisher">
                          <img
                            *ngIf="item.publisherImage != null"
                            src="{{ item.publisherImage }}"
                          />
                          <img
                            *ngIf="item.publisherImage == null"
                            src="../../assets/images/user-1.png"
                          />
                        </div>
                        <div class="publisher_name ml-md-3">
                          <h6 (click)="navigatePublisher(item.name)">
                            {{
                              item?.name?.slice(0, 14)
                                ? item?.name.slice(0, 14)
                                : item?.Name?.slice(0, 14)
                            }}
                          </h6>

                          <p class="d-flex p-p-t" *ngIf="item?.topics != ''">
                            Popular in
                            <span
                              class="d-flex ml-1"
                              *ngFor="
                                let topic of item?.topics?.slice(0, 3);
                                let last = last
                              "
                            >
                             {{ topic }}{{ !last ? ", " : "" }}</span
                            >
                          </p>
                        </div>
                        <div class="publisher_subscribe ml-auto">
                          <a
                            *ngIf="!item.followPublisher"
                            (click)="followPublisher(item)"
                            >Subscribe</a
                          >
                          <a *ngIf="item.followPublisher">Subscribed</a>
                          <img
                            (click)="closePubliser(i)"
                            src="../../assets/images/close.png"
                            id="close_img_p"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-r-bg">
                      <p>
                        Do you publish youtube videos on {{ activeState }} ?
                        <a
                          class="text-decoration-underline"
                          (click)="publisherLogin()"
                        >
                          Become a Publisher
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <h3 class="blogs_to_read">Recommended summaries to read</h3>
                <div>
                  <div
                    class="d-flex py-2"
                    *ngFor="let card of relatedCollection"
                  >
                    <div
                      class="related-c-img pointer"
                      (click)="realatedCollectionListBtn(card)"
                    >
                      <img
                        *ngIf="
                          card.thumbNail != '' && card.customThumbNail == null
                        "
                        src="{{ card.thumbNail }}"
                        class="img"
                        loading="lazy"
                        alt="{{ card.collectionName }}"
                      />
                      <img
                        *ngIf="
                          card.customThumbNail != '' && card.thumbNail == null
                        "
                        src="{{ card.customThumbNail }}"
                        class="img"
                        loading="lazy"
                        alt="customThumbnNail"
                      />
                    </div>
                    <div
                      class="related-c-header product_details_Paragraph ml-md-3"
                      (click)="realatedCollectionListBtn(card)"
                    >
                      <h4 class="mb-md-1">{{ card.summaryName }}</h4>
                      <p class="c-name mt-md-2 pointer">
                        <a>{{ card.publisherName }}</a>
                      </p>
                      <!-- <h2 class="c-name mt-md-2">Video views: {{card.videoViewsCount}}</h2> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="moblie-responsive" *ngFor="let item of toggles">
        <div class="resposive-moblie-fixed" *ngIf="item.NavbarToggle">
          <img
            src="../../assets/images/Icon awesome-user-circle.svg"
            class="fixed-img-dev"
          />
          <img
            src="../../assets/images/Icon simple-buzzfeed (1).svg"
            class="fixed-img-dev"
          />
          <img
            src="../../assets/images/Icon awesome-list-ul.svg"
            class="fixed-img-dev"
          />
          <img
            src="../../assets/images/Icon awesome-user-circle.svg"
            class="fixed-img-dev"
          />
        </div>
      </div>

      <div
        class="moblie-responsive"
        *ngFor="let collection of collectionList; let i = index"
      >
        <div class="channel-avatar-section">
          <div class="d-flex">
            <div>
              <img
                src="{{ collection.publisherImage }}"
                class="channel-image"
              />
            </div>
            <div class="channel-details">
              <a
                class="d-flex"
                (click)="navigateChannel(collection.publisherName)"
              >
                <h4>Published by</h4>
                <span class="text-decoration-underline ml-2">{{
                  collection?.publisherName
                }}</span>
              </a>
            </div>
            <div class="channel-details ml-md-3">
              <a
                class="subscribed_1"
                *ngIf="!collection.followPublisher"
                (click)="subscribeChannel(collection, true)"
                >Follow</a
              >
            </div>
            <div class="ml-auto">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu1"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>
                  <img
                    src="../../assets/images/dots.png"
                    class="report"
                    width="35px"
                  />
                  <!-- <p class="report-text">report a problem</p> -->
                </mat-icon>
              </button>
              <mat-menu #menu1="matMenu" class="mat-menu" xPosition="after">
                <button
                  mat-menu-item
                  class="mat-item-span"
                  *ngIf="!collection.bookmarkSummary"
                  (click)="
                    saveCollection(
                      collection,
                      true,
                      collection._id,
                      collection.summaryName
                    )
                  "
                >
                  <mat-icon>
                    <img
                      src="../../../assets/images/Icon feather-bookmark.svg"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Bookmark</span>
                </button>

                <button
                  mat-menu-item
                  class="mat-item-span"
                  (click)="link()"
                  cdkCopyToClipboard="{{ url }}/c/{{
                    collection?.summaryName?.split(' ')?.join('-')
                  }}/id-{{ collection._id }}"
                >
                  <mat-icon>
                    <img
                      src="../../../assets/images/Icon feather-link-2.svg"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Copy link</span>
                </button>
                <button mat-menu-item class="mat-item-span">
                  <mat-icon>
                    <img
                      src="../../../assets/images/not-equal.png"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Not interested in this</span>
                </button>
                <ng-container
                  *ngFor="
                    let categories of collection.topics
                      | fliterCategorty : { name: activeState }
                  "
                >
                  <button
                    mat-menu-item
                    class="mat-item-span"
                    (click)="unFollowCategories(categories, true, collection)"
                    *ngIf="categories.topicsFollow"
                  >
                    <mat-icon>
                      <img
                        src="../../../assets/images/Icon feather-delete.svg"
                        class="mr-3 spam-img"
                      />
                    </mat-icon>
                    <span>Unfollow {{ categories.name }}</span>
                  </button>
                </ng-container>
 <button
                              mat-menu-item
                              class="mat-item-span"
                              (click)="
                                dislikeSummary(collection, true, collection._id)
                              "
                            >
                              <mat-icon>
                                <img
                                  src="../../../assets/images/not-equal.png"
                                  class="mr-3 spam-img"
                                />
                              </mat-icon>
                              <span>Downvote</span>
                            </button>
                <button
                  mat-menu-item
                  class="mat-item-span"
                  (click)="UnfollowBtn(collection, true)"
                  *ngIf="collection.followPublisher"
                >
                  <mat-icon>
                    <img
                      src="../../../assets/images/sad.png"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Unfollow {{ collection.publisherName }}</span>
                </button>
                <button mat-menu-item class="mat-item-span" (click)="share(collection)">
                  <mat-icon>
                    <img
                      src="../../../assets/images/Icon awesome-share-alt.svg"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Share with a friend </span>
                </button>
                <button mat-menu-item class="mat-item-span">
                  <mat-icon>
                    <img
                      src="../../../assets/images/Icon awesome-share-alt.svg"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Dowload summary words</span>
                </button>
                <button mat-menu-item class="mat-item-span" (click)="report(collection)">
                  <mat-icon>
                    <img
                      src="../../../assets/images/Icon material-report-problem.svg"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Report a problem</span>
                </button>
                <button mat-menu-item class="mat-item-span">
                  <mat-icon>
                    <img
                      src="../../../assets/images/Icon zocial-email.svg"
                      class="mr-3 spam-img"
                    />
                  </mat-icon>
                  <span>Contact us</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="summary-start">
          <a
            href="/c/{{ collection?.summaryName?.split(' ')?.join('-') }}/id-{{
              collection._id
            }}"
            (click)="collectionsClick(collection._id)"
          >
            <h3>{{ collection.summaryName }}</h3>
          </a>
        </div>
        <div
          [class.active]="blogActive"
          *ngIf="collection.showSummaryComment == false"
          class="summary-paragrph summary-text"
          innerHTML="{{ collection.Summary?.substring(0, 400) }}"
        ></div>
        <div
          [class.active]="blogActive"
          *ngIf="collection.showSummaryComment == true"
          class="summary-paragrph summary-text"
          innerHTML="{{ collection.Summary?.substring(0) }}"
        ></div>
        <p class="comment">
          <span
            *ngIf="showFullTextTrue && collection.showSummaryComment == false"
            (click)="toggleShowMore(collection.Summary, collection, true)"
            ><a>Show more</a></span
          >
          <span
            *ngIf="showFullTextFalse && collection.showSummaryComment == true"
          >
            <a (click)="toggleShowMore(collection.Summary, collection, false)"
              >Show less</a
            >
          </span>
        </p>

        <img
          *ngIf="
            collection.thumbNail != '' && collection.customThumbNail == null
          "
          src="{{ collection.thumbNail }}"
          class="img-r"
          loading="lazy"
          alt="{{ collection.summaryName }}"
        />
        <img
          *ngIf="
            collection.customThumbNail != '' && collection.thumbNail == null
          "
          src="{{ collection.customThumbNail }}"
          class="img-r"
          loading="lazy"
          alt="customThumbnNail"
        />
        <p class="views">
          {{ collection.viewCount | records }} video views on Youtube
        </p>
        <div class="d-flex">
          <div class="d-md-flex">
            <div>
              <img
                src="../../../assets/images/vote.png"
                width="40px"
                *ngIf="!collection.summaryLike"
                (click)="likeCollections(collection, true, collection._id)"
              />
              <img
                src="../../../assets/images/vote (1).png"
                width="40px"
                *ngIf="collection.summaryLike"
              />
              <p
                *ngIf="
                  collection.summaryLike == false &&
                  collection.summaryLikeCount != 0
                "
              >
                Vote this summary
              </p>
              <!-- <p *ngIf="collection.summaryLikeCount != 0 && collection.summaryLikeCount == true">{{ collection.summaryLikeCount }} voted this summary</p> -->
              <p class="blink-sum" *ngIf="collection.summaryLike">
                you and {{ collection.summaryLikeCount }} others voted this
                summary
              </p>
            </div>
            <!-- 
            <div
              *ngFor="
                let categories of collection.topics
                  | fliterCategorty : { name: activeState }
              "
            >
              <div
                class="category-follow mt-md-4 mr-md-3 ml-md-4"
                *ngIf="!categories.topicsFollow"
              >
                <div class="d-flex">
                  <div class="catergory-details">
                    <div class="d-flex">
                      <a (click)="catergoryNavigate(categories.name)">
                        <h6 class="mb-md-2">
                          Want to read more summaries on
                          <span>{{ categories.name }}</span
                          >? join {{ categories.name }} community
                        </h6>
                      </a>
                      <a
                        class="not-login_catergory"
                        (click)="followBtn(categories, true, collection)"
                        >join</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="ml-auto">
            <img
              src="../../assets/images/s-r.png"
              width="20px cursor"
              (click)="share(collection)"
            />
          </div>
        </div>
        <div *ngFor="let item of toggles">
          <div class="new_comment_section" *ngIf="item.CommentToggle">
            <div class="d-flex">
              <div class="comment_content">
                <p>
                  This summary has been generated by AI from the
                  <a
                    class="text-decoration-underline"
                    target="_blank"
                    [href]="collection.video"
                    >Youtube video</a
                  >
                </p>
                <h4>
                  Comment this summary link in the youtube comments section and
                  help others discover this summary
                </h4>
              </div>
              <div
                class="Comment_img ml-auto cursor"
                (click)="writeComment(collection)"
              >
                <img src="../../assets/images/Icon awesome-comments.svg" />
                <p>Comment</p>
              </div>
            </div>
          </div>

          <div class="new_comment_section reddit" *ngIf="item.RedditToggle">
            <div class="d-flex">
              <div class="comment_content">
                <h4>
                  Submit this summary link in
                  <a class="text-decoration-underline">r/growthhacking</a>
                  sub-reddit. Earn more
                  <a class="text-decoration-underline">Karma </a>on Reddit
                </h4>
              </div>
              <div class="Comment_img ml-auto cursor">
                <img src="../../assets/images/Icon awesome-reddit.svg" />
                <p>Submit</p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="recommendedTopics != ''">
          <div class="recommend_topics my-3" *ngIf="(i + 1) % 10 === 5">
            <h6 class="ml-md-4">Recommended topics for you follow</h6>
            <div class="topics_butttons">
              <div class="grid">
                <button
                  *ngFor="
                    let item of recommendedTopics?.slice(0, 6);
                    let i = index
                  "
                  class="cursor mr-3"
                >
                  {{ item.name?.slice(0, 14) }}
                  <img
                    *ngIf="!item.topicFollowed"
                    (click)="followTopic(item)"
                    src="../../assets/images/Icon feather-plus.svg"
                    class="ml-2"
                  />
                  <img
                    *ngIf="item.topicFollowed"
                    src="../../assets/images/check.png"
                    class="ml-2"
                    width="14px"
                    height="14px"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="recommendedPublishers != ''">
          <div class="recommend_topics my-3" *ngIf="(i + 1) % 10 === 0">
            <h6 class="ml-md-4">Recommended publisher for you follow</h6>
            <!-- <div class="card">
                        <div class="card-body">
                          <h6 class="ml-md-4">Recommended publisher for you follow</h6>
                          <div class="publisher_topics_cards ml-md-4">
                            <div class="d-md-flex justify-content-md-around recommenede_data ">
                              <div class="card " *ngFor="let item of recommendedPublishers?.slice(0,6);let i = index">
                                <div class="card-body">
                                  <img src="../../assets/images/close-m.svg" id="close_p" (click)="closePubliser(i)">
                                  <div class="header_body">
                                    <img *ngIf="item.publisherImage != null" src="{{item.publisherImage}}">
                                    <img *ngIf="item.publisherImage == null" src="../../assets/images/user-1.png">
                                    <h4>{{item?.name.slice(0,14)}}</h4>
                                    <button *ngIf="!item.followChannel" class="cursor follow_publisher_card" (click)="followPublisher(item)">Follow</button>
                                    <button *ngIf="item.followChannel" class="cursor follow_publisher_card">Following</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
            <div class="publisher_follow-re">
              <div
                class="d-flex"
                *ngFor="
                  let item of recommendedPublishers?.slice(0, 6);
                  let i = index
                "
              >
                <div class="img">
                  <div class="header_body">
                    <img
                      width="30px"
                      *ngIf="item.publisherImage != null"
                      src="{{ item.publisherImage }}"
                    />
                    <img
                      width="30px"
                      *ngIf="item.publisherImage == null"
                      src="../../assets/images/user-1.png"
                    />
                  </div>
                </div>
                <div>
                  <h4>{{ item?.name.slice(0, 14) }}</h4>
                </div>
                <div class="ml-auto">
                  <button
                    *ngIf="!item.followPublisher"
                    class="cursor follow_publisher_card"
                    (click)="followPublisher(item)"
                  >
                    Follow
                  </button>
                  <button
                    *ngIf="item.followPublisher"
                    class="cursor follow_publisher_card"
                  >
                    Following
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer *ngIf="footer"></app-footer>
  </div>
</main>
